import { Injectable } from '@angular/core';
import { HelperService } from './helperService';
import { Router } from '@angular/router';
import { TranslateService} from '@ngx-translate/core';
import { DatePipe } from '@angular/common';

@Injectable({
    providedIn: 'root'
})
export class DataTemplateImportService extends HelperService{

    constructor(router: Router, translateService: TranslateService, datepipe: DatePipe) {
        super(router, translateService, datepipe)
    }

    getTemplateRoutinePlan(lines){
        let convertHeaders = [];
		let contents = [];
		for(var i=1;i<lines.length ;i++){
			var currentline = lines[i].split(",");
			if (currentline.length<=10) {
				if (currentline.length==10) {
					convertHeaders.push(
						currentline[0].replace(/"/gm, ''), 
						currentline[1].replace(/"/gm, ''), 
						currentline[2].replace(/"/gm, ''),
						currentline[3].replace(/"/gm, ''),
						currentline[4].replace(/"/gm, ''),
						currentline[5].replace(/"/gm, ''),
						currentline[6].replace(/"/gm, ''),
						currentline[7].replace(/"/gm, ''),
                        currentline[8].replace(/"/gm, ''),
					);
				} else {
					convertHeaders.push(currentline[0].replace(/"/gm, ''));
				}
			} else {
				contents.push(currentline);
			}
		}
		if (contents.length==0) {
			return
		}
		let storeParams = [];
		contents.forEach((m, mk) => {
			storeParams.push({
				customerid: m[0],
				customerAddressNo: m[8],
				visitdetail: []
			})
			m.forEach((mm, mmk) => {
				if (mmk>=9) {
					if (mm!="") {
						storeParams[mk]["visitdetail"].push(
							{
								visitdate: convertHeaders[mmk],
								setvisit: mm
							}
						)
					}
				}
			});
		});

        if(storeParams.length==0){
            console.log('data emty')
            return;
        }
        
        return storeParams
    }

    getTemplatePromotionCustomer(lines){
        var params = []
        for(var i=1;i<lines.length; i++){
			var currentline = lines[i].split(",");
            params.push({
                customerID: currentline[0],
                limitSet: currentline[2]
            });
		}
        return params
    }

    getTemplateCustomerTarget(lines){
        var params = []
        for(var i=1;i<lines.length ;i++){
			var currentline = lines[i].split(",");
			params.push({
				CustomerID: currentline[0],
				CustomerName: currentline[1],
				Amount: currentline[2]
			})
		}
        return params
    }

    getTemplatekpiGoal(lines){
        var params = []
        for(var i=1;i<lines.length;i++){
			var j=0;
            var currentline = []
			currentline = lines[i].split(",");
            currentline = currentline.filter(value => {
                return value!=""
            })
           if (currentline.length>1) {
            params.push({
                KpiName: currentline[0],
                DivisionNo: "",
                MetricTypeID: "",
                Salemans: currentline[1],
                StartDate: currentline[3],
                EndDate: currentline[4],
                DisplayOnHomeScreen: currentline[5],
                DisplayOnMobile: currentline[6],
                Amount: currentline[7]
            })
           }
		}
        return params;
    }

    getTemplateDiscount(lines){
        var params = []
        for(var i=1;i<lines.length ;i++){
			var j=0;
			var currentline = lines[i].split(",");
			params.push(currentline[0]);
		}
        return params;
    }
    
    getTemplatePresentation(lines){
        var params = []
        for(var i=1;i<lines.length ;i++){
			var j=0;
			var currentline = lines[i].split(",");
			params.push(currentline[0]);
		}
        return params;
    }

    getTemplateCustomerPOSM(lines){
        var params = []
        for(var i=1;i<lines.length ;i++){
			var j=0;
			var currentline = lines[i].split(",");
			params.push(currentline[0]);
		}
        return params;
    }

    getTemplateCustomerCutomerKpi(lines){
        var params = []
        for(var i=1;i<lines.length ;i++){
			var j=0;
			var currentline = lines[i].split(",");
			params.push(currentline[0]);
		}
        return params;
    }

}