import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgxPaginationModule } from 'ngx-pagination';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from "@ngx-translate/core";
import { LoadingModule } from '../../../../shared/loading/loading.module';
import { CustomerDialogModalComponent } from './customerdialog-modal.component';
import { NgSelectModule } from '@ng-select/ng-select';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        NgxPaginationModule,
        TranslateModule,
        LoadingModule,
        NgSelectModule
    ],
    declarations: [CustomerDialogModalComponent],
    entryComponents: [CustomerDialogModalComponent]
})
export class CustomerTargetDialogModule { }
