import { Component, OnInit, Input, QueryList, ViewChildren, EventEmitter, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { DataService } from '../../../../shared/services/dataService';
import { HelperService } from '../../../../shared/services/helperService';
import { TranslateService } from '@ngx-translate/core';
import {NgbdSortableHeader, SortEvent} from '../saleTeamdialog-modal/saleTeam.sortable.directive';

@Component({
	selector: 'app-salemandialog-modal',
	templateUrl: './saleTeamdialog-modal.component.html',
	styleUrls: ['./saleTeamdialog-modal.component.scss'],
})
export class SaleTeamDialogModalComponent implements OnInit {

	@Input() type
	@Input() url
    @Input() param
	@Output() close: EventEmitter<any> = new EventEmitter()

    loading: boolean = false;
	loadingSg: boolean = false;
	saleTeam: any;
	saleGroups: any = [];

    numPage: string = "1";
    itemsPerPageList: any = [];
	itemsPerPage: string;
	totalItems: number;

	column: string = "SellerTeam";
	direction: string = "desc";

	constructor(
		public activeModal: NgbActiveModal,
		private dataService: DataService,
		private translate: TranslateService,
		private helperService: HelperService
	) {

	}
	filterTypes: any = {
		sellerGroupNo: null,
        search: ""
	}

	@ViewChildren(NgbdSortableHeader) headers: QueryList<NgbdSortableHeader>;

	ngOnInit() {

        this.itemsPerPageList = this.helperService.getItemsPerPageList();
		this.itemsPerPage = this.itemsPerPageList[0].value;
        
		this.getSaleTeams();
		this.getSaleGroup();
	}

	onSort({column, direction}: SortEvent) {
		this.headers.forEach(header => {
			if (header.sortable !== column) {
				header.direction = '';
			}
		});
	
		if (direction !== '' && column !== '') {
			this.column = column;
			this.direction = direction;
			this.getSaleTeams();
		}
	}
    
	getSaleTeams() {
		this.loading = true
        this.param.saleGroupNo = this.filterTypes.sellerGroupNo
        this.param.perPage = this.itemsPerPage
		this.param.page = this.numPage
        this.param.searchTerm = this.filterTypes.search
        this.param.action = this.type
		this.dataService.getDataRequest(this.url[0], this.param)
        .subscribe(
            result => {
                this.totalItems = result.totalRecord
                this.saleTeam = result.data
                this.saleTeam.map(function(obj) {
                    obj.selected = false
                    return obj
                });
                this.loading = false
            },
            error => {
                this.loading = false
                this.helperService.handleErrorResponse(error)
        });
	}

	OnchangeSellerGroup(){
		this.getSaleTeams()
	}

	onSelectedAll(e) {
		this.saleTeam.map(function(obj) {
			obj.selected = e
			return obj
		});
	}

	onSelected(e, isCheck) {
		e.selected = isCheck
	}

	onSubmit() {
		let obj = this.saleTeam.filter(function(e){
			return e.selected == true
		}).map(function(e){
            return e.SellerNo
		});
		if (obj.length == 0) {
			this.helperService.getTranslateAlertSweetResult(
				'error', 
				this.translate.instant('alert.error'),
				this.translate.instant('alert.pls.select.sale.team')
			);
			return;
		}
        this.param.action = this.type
        this.param.saleTeamNo = obj
		this.dataService.getDataRequest(this.url[2], this.param)
		  	.subscribe(
            result => {
                this.getSaleTeams()
            },
            error => {
				this.helperService.handleErrorResponse(error)
		});
	}

	closeModel() {
		this.close.emit(true);
	}

    onPageChange(e){
        this.numPage = e;
		this.getSaleTeams();
    }

    onChangePageSize(){
        this.numPage="1";
		this.getSaleTeams();
    }

    search(){
        this.numPage = "1"
        this.getSaleTeams()
    }

	getSaleGroup() {
        
        let param = this.param
        param.action = "remove"
        
		this.dataService.getDataRequest(this.url[1], param)
		.subscribe(
            result => {
                this.saleGroups = result
            },
            error => {
                this.helperService.handleErrorResponse(error)
		});
	}
}
