import { Component, OnInit, ViewChild, ElementRef, Renderer2, AfterViewInit } from "@angular/core";
import { ROUTES } from './sidebar-routes.config';
import { Router, ActivatedRoute } from "@angular/router";
import { TranslateService } from '@ngx-translate/core';
import { customAnimations } from "../animations/custom-animations";
import { ConfigService } from '../services/config.service';
import { Constants } from '../../constants/constant';
import { mainStyle } from '../../shared/template-config/template.config';

@Component({
  selector: "app-sidebar",
  templateUrl: "./sidebar.component.html",
  animations: customAnimations
})
export class SidebarComponent implements OnInit, AfterViewInit {

  @ViewChild('toggleIcon', {static: false} ) toggleIcon: ElementRef;
  public menuItems: any[];
  depth: number;
  activeTitle: string;
  activeTitles: string[] = [];
  expanded: boolean;
  nav_collapsed_open = false; 
  public config: any = {};
  public style: any = mainStyle
  public logoUrl: string = ""


  constructor(
    private elementRef: ElementRef,
    private renderer: Renderer2,
    private router: Router,
    private route: ActivatedRoute,
    public translate: TranslateService,
    private configService: ConfigService,
  ) {
    if (this.depth === undefined) {
      this.depth = 0;
      this.expanded = true;
    }
  }

  ngOnInit() {
	this.config = this.configService.templateConf;
	var userPermissions = JSON.parse(localStorage.getItem('user_permissions'));
	var menuName = [];
	
	userPermissions.forEach(m => {
		m.Function.forEach(mm => {
			menuName.push(mm.FunctionName)
		});
	});

	var itemMenus = [];
	ROUTES.forEach((m, kk) => {
		if (m.submenu.length==0) {
			if (menuName.includes(m.title2)) {
				itemMenus.push(m)
			}
		} else {
			var dd = m.submenu.filter((element) => {
				return menuName.includes(element.title2)
			});
			if (dd.length>0) {
				itemMenus.push(m)
				var index = itemMenus.indexOf(m)
				itemMenus[index].submenu = dd
			}
		}
	})

	this.menuItems=itemMenus
	
    if (this.config.layout.sidebar.backgroundColor === 'white') {
      this.logoUrl = this.style.logoDark;
    }
    else {
      this.logoUrl = this.style.logo;
    }
  }

  ngAfterViewInit() {

    setTimeout(() => {
      if (this.config.layout.sidebar.collapsed != undefined) {
        if (this.config.layout.sidebar.collapsed === true) {
          this.expanded = false;
          this.renderer.addClass(this.toggleIcon.nativeElement, 'ft-toggle-left');
          this.renderer.removeClass(this.toggleIcon.nativeElement, 'ft-toggle-right');
          this.nav_collapsed_open = true;
        }
        else if (this.config.layout.sidebar.collapsed === false) {
          this.expanded = true;
          this.renderer.removeClass(this.toggleIcon.nativeElement, 'ft-toggle-left');
          this.renderer.addClass(this.toggleIcon.nativeElement, 'ft-toggle-right');
          this.nav_collapsed_open = false;
        }
      }
    }, 0);


  }

  toggleSlideInOut() {
    this.expanded = !this.expanded;
  }

  onPage() {
    localStorage.setItem(Constants.LOCAL_NUM_PAGE, "");
    localStorage.setItem(Constants.LOCAL_STORAGE_SEARCH, "");
  }

  handleToggle(titles) {
    this.activeTitles = titles;
  }

  // NGX Wizard - skip url change
  ngxWizardFunction(path: string) {
    if (path.indexOf("forms/ngx") !== -1)
      this.router.navigate(["forms/ngx/wizard"], { skipLocationChange: false });
  }
}
