import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { Configuration } from '../../app.config';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { Constants } from '../../constants/constant';

@Injectable()
export class AuthService {
	token: string;

	constructor(
		private toastr: ToastrService, 
        private configuration: Configuration,
        public http: HttpClient
	) {}

	userAuthentication(userName, password) {
        var data = {
            "username":userName,
            "password":password,
            "platform": "web",
            "client_id": this.configuration.clientId,
            "client_secret": this.configuration.clientSecret,
            "grant_type": "password",
            "scope": "",
        }
        return this.http.post(this.configuration.apiServerUrl + 'oauth/token',data, this.hearderlogin());
    }

	signupUser(email: string, password: string) {
	//your code for signing up the new user
	}

	signinUser(email: string, password: string) {
	//your code for checking credentials and getting tokens for for signing in user
	}

	logout() {   
		this.token = null;
	}

	getToken() {    
		return this.token;
	}

	isAuthenticated() {
		const token = localStorage.getItem(Constants.LOCAL_STORAGE_USER_TOKEN);
        const tokenExpired = localStorage.getItem(Constants.LOCAL_STORAGE_USER_TOKEN_EXPIRED_AT);
        const nowInMilliseconds = new Date().getTime();

        // if no token or token expired return false
        if (token == null || nowInMilliseconds > parseInt(tokenExpired)) {
            return false;
        }

		return true;
	}

	hearderlogin(){
        let headers = new HttpHeaders({ 
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*'
        });
        let options = { 
            headers: headers,
            crossDomain: true
        };
        return options
    }
}
