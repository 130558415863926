import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { DataService } from '../../../../shared/services/dataService';
import { PrintService } from "../../../../shared/services/print.service";

@Component({
    selector: "app-list-product",
    templateUrl: "./list.component.html",
    styleUrls: ["./list.component.scss"],
})
export class ListComponent implements OnInit  {
    
    data: any = []
    loading:  boolean = false
    invoiceIds: any = [];
    queryParams: any = [];

    constructor(
        public route: ActivatedRoute,
        public printService: PrintService,
        private dataService: DataService
    ) {
        this.route.queryParams.subscribe(params => {
            this.queryParams = JSON.parse(params.queryParams)
        });
    }

    async ngOnInit() {
        let respon = await this.dataService.getDataRequest('inventory', this.queryParams).toPromise().then(res => res).catch(err => err);
        if (respon.status!=200&&respon.status!=undefined) {
        }else{
            this.printService.onDataReady()
            this.data = respon.dataInventoryProduct
        }
    }
}
