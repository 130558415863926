import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgxPaginationModule } from 'ngx-pagination';
import { FormsModule } from '@angular/forms';
import { LoadingModule } from '../../../../shared/loading/loading.module';
import { BranchdialogModalComponent } from './branchdialog-modal.component';
import { TranslateModule } from "@ngx-translate/core";

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        NgxPaginationModule,
        LoadingModule,
        TranslateModule
    ],
    declarations: [BranchdialogModalComponent],
    entryComponents: [BranchdialogModalComponent]
})
export class BranchDialogModule { }
