import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { RouterModule } from "@angular/router";

import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { TranslateModule } from "@ngx-translate/core";
import { PerfectScrollbarModule } from "ngx-perfect-scrollbar";

//COMPONENTS
import { CustomizerComponent } from "./customizer/customizer.component";
import { FooterComponent } from "./footer/footer.component";
import { NavbarComponent } from "./navbar/navbar.component";
import { SidebarComponent } from "./sidebar/sidebar.component";
import { PrintLayoutComponent } from "../pages/print-layout/print-layout.component";
import { NotificationSidebarComponent } from "./notification-sidebar/notification-sidebar.component";

//DIRECTIVES
import { ToggleFullscreenDirective } from "./directives/toggle-fullscreen.directive";
import { SidebarDirective } from "./directives/sidebar.directive";
import { SidebarLinkDirective } from "./directives/sidebarlink.directive";
import { SidebarListDirective } from "./directives/sidebarlist.directive";
import { ActionAccessControlDirective } from "./directives/action-AccessControl.directive";
import { ActionSpecialKeyDirective } from "./directives/action-specialKey.directive";
import { SidebarAnchorToggleDirective } from "./directives/sidebaranchortoggle.directive";
import { SidebarToggleDirective } from "./directives/sidebartoggle.directive";
import { OnlyNumberNotDot } from "./directives/onlynumberNotDot.directive";
import { ChangePassworddialogModalComponent } from "../shared/dialogs/changePassworddialog-modal/changepassworddialog-modal.component";

import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MonthDatePickerComponent } from "./component/month-date-picker/month-date-picker.component";
import { ProgressingBarComponent } from "./component/progressing-bar/progressing-bar.component";
import { ProgressingBarComponent as ProgressingBarComponent2 } from "./component/progressing-bar2/progressing-bar.component";
import { ImageLightboxComponent } from "./component/image-lightbox/image-lightbox.component";
import { DropDownComponent } from "./component/drop-down/drop-down.component";
import { UploadFileModule } from "./component/upload-file/upload.module";
import { DataTemplateImportService } from '../shared/services/dataTemplateImport.service';
import { PrintService } from '../shared/services/print.service';
import { PrintModule } from "../pages/print-layout/print.module";
import { BranchDialogModule } from "../pages/components/dialogs/branchdialog-modal/branchdialog.module";
import { SaleGroupDialogModule } from "../pages/components/dialogs/saleGroupdialog-modal/salemandialog.module";
import { SaleTeamdialogModule } from "../pages/components/dialogs/saleTeamdialog-modal/saleTeamdialog.module";
import { CustomerTargetDialogModule } from "../pages/components/dialogs/customersdialog-modal/customerdialog-modal.module";
import { EditInputComponent } from '../pages/components/edit-input/edit-input.component';

@NgModule({
  exports: [
    CommonModule,
    UploadFileModule,
    NgbModule,
    TranslateModule,
    ToggleFullscreenDirective,
    SidebarDirective,
    ActionAccessControlDirective,
    ActionSpecialKeyDirective,
    OnlyNumberNotDot,
    FooterComponent,
    NavbarComponent,
    SidebarComponent,
    NotificationSidebarComponent,
    CustomizerComponent,
    PrintLayoutComponent,
    MonthDatePickerComponent,
    ProgressingBarComponent,
    ProgressingBarComponent2,
    ImageLightboxComponent,
    DropDownComponent,
    EditInputComponent
  ],
  imports: [
    RouterModule,
    CommonModule,
    NgbModule,
    TranslateModule,
    PerfectScrollbarModule,
    FormsModule,
    ReactiveFormsModule,
    UploadFileModule,
    PrintModule,
    BranchDialogModule,
    SaleGroupDialogModule,
    SaleTeamdialogModule,
    CustomerTargetDialogModule
  ],
  declarations: [
    FooterComponent,
    NavbarComponent,
    SidebarComponent,
    CustomizerComponent,
    EditInputComponent,
    NotificationSidebarComponent,
    ChangePassworddialogModalComponent,
    MonthDatePickerComponent,
    ProgressingBarComponent,
    ProgressingBarComponent2,
    PrintLayoutComponent,
    ImageLightboxComponent,
    DropDownComponent,
    ToggleFullscreenDirective,
    SidebarDirective,
    SidebarLinkDirective,
    SidebarListDirective,
    ActionAccessControlDirective,
    ActionSpecialKeyDirective,
    SidebarAnchorToggleDirective,
    SidebarToggleDirective,
    OnlyNumberNotDot
  ],
  entryComponents: [
    ChangePassworddialogModalComponent,
    ProgressingBarComponent,
    ProgressingBarComponent2,
    ImageLightboxComponent
  ],
  providers: [DataTemplateImportService, PrintService],
})
export class SharedModule {}
