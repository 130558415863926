
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { SharedModule } from "./shared/shared.module";
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { HttpClientModule, HttpClient } from "@angular/common/http";
import { TranslateModule, TranslateLoader } from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';
import { DataService } from './shared/services/dataService';
import { HttpService } from './shared/services/httpService';
import { HelperService } from './shared/services/helperService';
import { CookieService } from 'ngx-cookie-service';
import { ToastrModule } from "ngx-toastr";
import { Configuration } from './app.config';
import { UiSwitchModule } from 'ngx-ui-switch';
import { 
    PerfectScrollbarModule, 
    PERFECT_SCROLLBAR_CONFIG, 
    PerfectScrollbarConfigInterface
  } from 'ngx-perfect-scrollbar';
import { AppComponent } from './app.component';
import { ContentLayoutComponent } from "./layouts/content/content-layout.component";
import { FullLayoutComponent } from "./layouts/full/full-layout.component";
import { AuthService } from './shared/auth/auth.service';
import { AuthGuard } from './shared/auth/auth-guard.service';
import { RoleGuard } from './shared/auth/role-guard.service';
import { ActionGuard } from './shared/auth/action-guard.service';
import { SpecialKeyGuard } from './shared/auth/specialKey-guard.service';
import { DatePipe } from '@angular/common';

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
    suppressScrollX: true,
    wheelPropagation: false
  };
  
  export function createTranslateLoader(http: HttpClient) {
    return new TranslateHttpLoader(http, "./assets/i18n/", ".json");
  }

  @NgModule({
    declarations: [
      AppComponent,
      FullLayoutComponent,
      ContentLayoutComponent
    ],
    imports: [
      BrowserAnimationsModule,
      AppRoutingModule,
      SharedModule,
      HttpClientModule,
      NgbModule.forRoot(),
      SweetAlert2Module.forRoot(),
      TranslateModule.forRoot({
        loader: {
          provide: TranslateLoader,
          useFactory: createTranslateLoader,
          deps: [HttpClient]
        }
      }),
      ToastrModule.forRoot({
        timeOut:1500,
        positionClass:'toast-top-right',
        preventDuplicates:false
      }),
      UiSwitchModule.forRoot({
        color: '#0CC27E',
      }),
      PerfectScrollbarModule,
    ],
    providers: [
      AuthService,
      AuthGuard,
      RoleGuard,
      ActionGuard,
      SpecialKeyGuard,
      Configuration,
      CookieService,
      DataService,
      HttpService,
      HelperService,
      DatePipe,
      {
        provide: PERFECT_SCROLLBAR_CONFIG,
        useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG
      },
      { provide: PERFECT_SCROLLBAR_CONFIG, useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG }
    ],
    bootstrap: [AppComponent]
  })
export class AppModule {}


@NgModule({
	exports: [
		TranslateModule
	]
})

export class TranslateSharedModule { }