import { Component, OnInit, ViewChild, ElementRef, Input, EventEmitter, Output } from '@angular/core';

@Component({
    selector: 'app-offcanvas',
    templateUrl: './offcanvas.component.html',
    styleUrls: ['./offcanvas.component.scss']
})
export class OffcanvasComponent implements OnInit {

    @ViewChild('offcanvasSildbar', { static: false }) modal: ElementRef;
    @Output() close: EventEmitter<boolean> = new EventEmitter(false);
    @Input() title

    constructor() {}

    ngOnInit() {} 

    closeOffCanvas() {
        this.modal.nativeElement.remove();
        document.body.style.overflow = 'auto'
        this.close.emit(true)
    }
}
