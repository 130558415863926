import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgxPaginationModule } from 'ngx-pagination';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from "@ngx-translate/core";
import { LoadingModule } from '../../../../shared/loading/loading.module';
import { SalemandialogModalComponent } from './salemandialog-modal.component';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        NgxPaginationModule,
        TranslateModule,
        LoadingModule
    ],
    declarations: [SalemandialogModalComponent],
    entryComponents: [SalemandialogModalComponent]
})
export class SaleGroupDialogModule { }
