import { Component, Input, OnInit } from '@angular/core';
import { DataService } from '../../../shared/services/dataService';
import { HelperService } from '../../../shared/services/helperService';
import { Workbook } from 'exceljs';
import * as fs from 'file-saver';
import { MODULE, DateType } from '../../../constants/constant';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-download-file',
  templateUrl: './download-file.component.html',
  styleUrls: ['./download-file.component.scss']
})
export class DownloadFileComponent implements OnInit {

    @Input() module
    @Input() params
    @Input() isClose

    loading: boolean = false

    constructor(
        private dataService: DataService,
		private helperService: HelperService,
        private router: Router,
        private translate: TranslateService
    ) { }

    ngOnInit() {
    }

    download(){
        switch(this.module) { 
            case MODULE.ROUTING_PLAN:{
                this.routinePlanFile()
                break;
            }
            case MODULE.PROMOTION:{
                window.location.href='../../../../../assets/customer_info.xlsx';
                break;
            }
            case MODULE.CUSTOMER_TARGET:{
                this.params.searchText = "",
                this.params.Id = this.params.customerTargetID
                this.downloadRequestFile(
                    'get/customer_target_detail'
                );
            }
            case MODULE.KPI_GOAL:{
                window.location.href='../../../../../assets/import-kip.xlsx';
                break;
            }
            case MODULE.DISCOUNT:{
                window.location.href='../../../../../assets/customer_infoDiscount.xlsx';
                break;
            }
            case MODULE.PRESENTATION:{
                window.location.href='../../../../../assets/customer_infoPresentation.xlsx';
                break;
            }
            case MODULE.POSM:{
                window.location.href='../../../../../assets/customer_POSM.xlsx';
                break;
            }
            case MODULE.CUSTOMER_KPI:{
                window.location.href='../../../../../assets/customer_kpi.xlsx';
                break;
            }
            default: { 
                break; 
            } 
        }
    }

    routinePlanFile(){
        this.loading = true
		this.dataService.importCustomerRoutinePlan({RoutinePlanNo: this.params.routineplanno})
			.subscribe(
			result => {
                this.loading = false
				this.routineLayoutExcel(result);
			},
			error => {
                this.loading = false
				if (error.statusText === 'Unauthorized') {
					return this.router.navigate(['/login']);
				}
			}
		);
    }

    routineLayoutExcel(respon) {
		var mm = respon.headerDate
		var customer = respon.customer
		let workbook = new Workbook();
		let worksheet = workbook.addWorksheet('sheet');
		let header = [
			{
				Date : this.translate.instant("form.customer.id"),
				Isholiday: false
			},
			{
				Date: this.translate.instant("form.customer.name"),
				Isholiday: false
			},
			{
				Date: this.translate.instant("table.frequency.type"),
				Isholiday: false
			},
            {
				Date: this.translate.instant("table.zone"),
				Isholiday: false
			},
            {
				Date: this.translate.instant("table.commune"),
				Isholiday: false
			},
			{
				Date: this.translate.instant("table.district"),
				Isholiday: false
			},{
				Date: this.translate.instant("form.province"),
				Isholiday: false
			},
			{
				Date: this.translate.instant("form.shipping.address"),
				Isholiday: false
			},{
				Date: "CustomerAddressNo",
				Isholiday: false
			}
		]
		worksheet.addRow(
			[
				this.translate.instant("common.holiday"), 
				this.translate.instant("common.day.off"), 
				this.translate.instant("common.day.half"),
                this.translate.instant("common.non.visite")
			]
		);
        worksheet.getCell('D1').fill = {
			type: 'pattern',
			pattern: 'solid',
			fgColor:{argb:'1CBCD8'},
			bgColor:{argb:'1CBCD8'}
		};
		worksheet.getCell('C1').fill = {
			type: 'pattern',
			pattern: 'solid',
			fgColor:{argb:'FF8D60'},
			bgColor:{argb:'FF8D60'}
		};
		worksheet.getCell('B1').fill = {
			type: 'pattern',
			pattern: 'solid',
			fgColor:{argb:'FF586B'},
			bgColor:{argb:'FF586B'}
		};
		worksheet.getCell('A1').fill = {
			type: 'pattern',
			pattern: 'solid',
			fgColor:{argb:'0CC27E'},
			bgColor:{argb:'0CC27E'}
		};
		worksheet.getCell('A1').alignment = { horizontal: 'center' };
		worksheet.getCell('B1').alignment = { horizontal: 'center' };
		worksheet.getCell('C1').alignment = { horizontal: 'center' };
        worksheet.getCell('D1').alignment = { horizontal: 'center' };
	
		let children  = header.concat(mm);
		let headerRow = worksheet.addRow(children);

		let colWidth = [];
		children.forEach((m, k) => {
			colWidth.push({width: k==8?0:20});
		});

		worksheet.columns = colWidth
		interface SquareConfig {
			fill?: any,
			font?: any,
			border?: any,
			alignment?: any,
			value?: any
		}

		headerRow.eachCell((cell: SquareConfig, number) => {
			cell.fill = {
				type: 'pattern',
				pattern: 'solid',
				bgColor: { argb: 'FF0000FF' }
			}
			if(cell.value.DayOffType==DateType.PUBLIC_HOLIDay) {
				cell.fill.fgColor = { argb: '0CC27E' }
			} else if (cell.value.DayOffType==DateType.HALF) ​​​​{
				cell.fill.fgColor = { argb: 'FF8D60' }
			} else if (cell.value.DayOffType==DateType.DAYOFF) {
				cell.fill.fgColor = { argb: 'FF586B' }
			}else if (cell.value.DayOffType==DateType.NON_VISIT) {
                cell.fill.fgColor = { argb: '1CBCD8' }
            }else {
				cell.fill.fgColor = { argb: 'FFFFFF' }
			}
			cell.value = `${cell.value.DateName==undefined?"":cell.value.DateName}\r\n${cell.value.Date}`
			cell.font = { color: { argb: '000000' }}
			cell.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' }}
			cell.alignment = { horizontal: 'center', wrapText: true }
		})

		var dt = [];
		customer.forEach((d, i) => {
				dt.push([
					d.CustomerID,
					d.CustomerNameEN,
					d.FrequencyType,
                    d.ZoneName,
                    d.CommuneEn,
					d.DistrictEn,
					d.ProvinceEn,
					d.AddressName,
					d.CustomerAddressNo
				]);
			}
		);
		
		dt.forEach((element, i) => {
			worksheet.addRow(element);
		});

		workbook.xlsx.writeBuffer().then((dt) => {
		  let blob = new Blob([dt], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
		  fs.saveAs(blob, 'customer_routine_plan_template','.xlsx');
		})
	}

    async downloadRequestFile(url){
        var pageSize = this.helperService.getNumberPageExport();
        this.params.pageSize = pageSize
        this.params.page = 0
        var totalRecord = this.params.totalRecord;
        this.loading = false

        var data = []
        for (let index = 1; index <= Math.ceil((totalRecord/pageSize)); index++) {
            this.loading = true
            if(this.isClose){return;}
            this.params.page = index
            var respon = await this.dataService.getDataRequest(url, this.params).toPromise().then(res => res).catch(err => err);
            if (respon.status!=200&&respon.status!=undefined) {
				if (respon.statusText === 'Unauthorized') {
					return this.router.navigate(['/login']);
				}
                this.loading = false
				break
			} else {
				respon.data.map(mm => {
					data.push(mm)
				})
			}
        }

        if (data.length==totalRecord) {
            this.loading = false
			this.layOutReport(data)
		}
    }

    layOutReport(data){
        if(MODULE.CUSTOMER_TARGET==this.module){this.customerTargetlayout(data)}
    }

    customerTargetlayout(data) {
        const header = [
			'CustomerID',
			'CustomerName',
			'Amount'
		];

		if (this.params.type==2) {
			header[2] = 'Qty'
		}
        
		let workbook = new Workbook();
		let worksheet = workbook.addWorksheet('Sheet1');
		let headerRow = worksheet.addRow(header);

		worksheet.columns = [ 
			{ key: 'A', width: 15 },
			{ key: 'B', width: 15 },
			{ key: 'C', width: 15 }
		];

		headerRow.eachCell((cell, number) => {
		  cell.fill = {
			type: 'pattern',
			pattern: 'solid',
			fgColor: { argb: '0aa74c' },
			bgColor: { argb: 'FF0000FF' }
		  }
		  cell.font = { color: { argb: 'FFFFFF' }}
		  cell.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' }}
		  cell.alignment = { horizontal: 'center' }
		})
		
		var dt = [];
		data.forEach((d, i) => {
				dt.push([
					d.CustomerID,
					d.CustomerNameEN,
					''
				]);
			}
		);
		
		dt.forEach((element, i) => {
			worksheet.addRow(element);
		});

		workbook.xlsx.writeBuffer().then((dt) => {
		  let blob = new Blob([dt], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
		  fs.saveAs(blob, this.translate.instant('customer_template'),'.xlsx');
		})
	}
}
