import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router} from '@angular/router';
import { Observable, TimeoutError } from 'rxjs';
import { Globals } from '../services/globals';

@Injectable()
export class SpecialKeyGuard implements CanActivate{

	constructor(private _router: Router) { }

	canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {

		var userPermissions = JSON.parse(localStorage.getItem('user_permissions'));
		if (userPermissions.length>0) {
			var menu = userPermissions.filter(mm => {
				return mm.Function.find(mmm => {
					return mmm.FunctionName==next.data.menu
				})
			}).map(m => {
				return m.Function.find(mm => {
					return mm.FunctionName == next.data.menu
				})
			}).map(m => {
				return m.SpecialKey.find(mm => {
					return mm.SpecialPermissionKey == next.data.action
				})
			})
			if(menu.length>0) {
				if(menu[0]==undefined){this._router.navigate(['/error/404']);return false}
				if(menu[0].AccessRight==1) {
					return true
				}
			}
		}

		this._router.navigate(['/error/404'])
		return false
	}
}