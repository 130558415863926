import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { RouterModule } from "@angular/router";

import { LoadingModule } from '../../shared/loading/loading.module';
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { TranslateModule } from "@ngx-translate/core";
import { ListComponent as ProductComponent } from "./pages/product/list.component";

@NgModule({
  exports: [
    CommonModule,
    NgbModule,
    TranslateModule
  ],
  imports: [
    RouterModule,
    CommonModule,
    NgbModule,
    TranslateModule,
    LoadingModule
  ],
  declarations: [
    ProductComponent
  ]
})
export class PrintModule {}
