import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { DataService } from '../../../../shared/services/dataService';
import { HelperService } from '../../../../shared/services/helperService';
import { TranslateService } from '@ngx-translate/core';

@Component({
	selector: 'app-branchdialog-modal',
	templateUrl: './branchdialog-modal.component.html', 
	styleUrls: ['./branchdialog-modal.component.scss'], 
})
export class BranchdialogModalComponent implements OnInit {

	@Input() type
    @Input() url
    @Input() param
	@Output() close: EventEmitter<any> = new EventEmitter()

    loading: boolean = false
	branches: any = []

	constructor(
		public activeModal: NgbActiveModal,
		private dataService: DataService,
		private translate: TranslateService,
		private helperService: HelperService
	) {}

	ngOnInit() {
	    this.getBranchs()
	}

	getBranchs() {
		this.loading = true;
		this.dataService.getDataRequest(this.url[0], this.param)
		  	.subscribe(
				result => {
					this.branches = result;
					this.branches.map(function(obj) {
						obj.selected = false
						return obj
					});
					this.loading = false;
				},
				error => {
					this.loading = false;
					this.helperService.handleErrorResponse(error)
				}
		);
	}
	
	onSelectedAll(e) {
		this.branches.map(function(obj) {
			obj.selected = e
			return obj;
		});
	}

	onSelected(e, isCheck) {
		e.selected = isCheck;
	}

	onSubmit() {
		const obj = this.branches.filter(function(e){
			return e.selected == true
		}).map(function(e){
			return e.BranchNo
		})
		if (obj.length == 0) {
			this.helperService.getTranslateAlertSweetResult(
				'error', 
				this.translate.instant('alert.error'),
				this.translate.instant('alert.pls.select.branche')
			);
			return;
		}
        this.loading = false
        this.param.branchNo = obj
        this.dataService.getDataRequest(this.url[1], this.param)
		  	.subscribe(
				result => {
					this.getBranchs()
				},
				error => {
					this.loading = false;
					this.helperService.handleErrorResponse(error)
				}
		);
	}

	closeModel() {
		this.close.emit(true);
	}
}
