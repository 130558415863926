import { Injectable } from '@angular/core';
import { HttpService } from './httpService'
import { TranslateService } from '@ngx-translate/core';
import { HttpParams, HttpHeaders } from '@angular/common/http';

@Injectable()
export class DataService {
	constructor(
		private http: HttpService,
		private translate: TranslateService,
	) { }
	
	// Api for user management
	createUser(body) {
		return this.http.post('create_user', body);
    }
    getAllUsers(param) {
		return this.http.post('user_list', param);
    }
    delectUser(param, body) {
		return this.http.put('delete_user', param, body);
	}
	changeUserPassword(body) {
		return this.http.post('user/reset_password', body);
	}
	
	unlockUser(id){
		return this.http.post('user/unlock', id);
	}

	getUser(param) {
		return this.http.get('user/view', param);
	}
	editUser(param, body) {
		return this.http.put('update_user', param, body);
	}
	resetPasswordUser(body) {
		return this.http.post('user/reset_password', body);
	}
    setRequiredAndUpdate(body){
        return this.http.post('user/set-required-and-update', body);
    }

	// Promotion
	createPromotion(body, promotionImage :File) {
		return this.http.postWithImage('promotion/store', body, promotionImage);
	}

	getAllPromotions(body) {
		return this.http.post('promotion', body);
	}
	getPromotion(param) {
		return this.http.get('promotion/get_by_cus_id', param);
	}
	addCustomerPromotion(body) {
		return this.http.post('promotion/add_cus', body);
	}
	getExitedCustomerPromotion(body) {
		return this.http.post('promotion/get_promo_customers', body);
	}
	removeCustomerPromotion(body) {
		return this.http.post('promotion/remove_customers', body);
	}
	addBranchesPromotion(param, body) {
		return this.http.post('promotion/add_branches/'+param, body);
	}
	editLimitsetPromotion(body) {
		return this.http.post('promotion/update_cus_limitset', body);
	}
	deletePromotion(body) {
		return this.http.post('promotion/delete', body);
	}
	removeBranchesPromotion(body) {
		return this.http.post('promotion/remove_branches', body);
	}
	addItemsPromotion(param, body) {
		return this.http.post('promotion/add_item/'+param, body);
	}
	activatedPromotion(param, body) {
		return this.http.post('promotion/activate/'+param, body);
	}
	delectPromotionItems(body) {
		return this.http.post('promotion/remove_items', body);
	}
	getSalemanGroup(body) {
		return this.http.post('promotion/getListSalemanGroup', body);
	}
	addSalemanGroup(param, body) {
		return this.http.post('promotion/add_saleman_groups/'+param, body);
	}
	removeSalemanGroup(body) {
		return this.http.post('promotion/removeSalemanGroup', body);
	}

	// get Items
	getItemsByUserDivision2(body) {
		return this.http.post('inventory2', body);
	}
	getItemsByUserDivision(body) {
		return this.http.post('inventory', body);
	}
	getPackingsItem(body) {
		return this.http.post('inventory/getpackings', body);
	}

	// get Customer by Division
	getCustomersByDivision(body) {
		return this.http.post('customerlist', body);
	}
	customer_salesman(body) {
		return this.http.post('customer_salesman', body);
	}

	// get branches
	getBranches(body) {
		return this.http.post('branches', body);
    }
    
    // get Division
    getDivisions() {
		return this.http.get('user/division');
    }
    
    // get Client
    getClients() {
        return this.http.get('user/client');
	}
	
	// get Saleman
	getSaleman(param) {
		return this.http.get('user/saleman', param.toString());
	}

	// kpi
	postUpdateMetricType(body) {
		return this.http.post('kpi/saveMetricType', body);
	}
	postSaveKPIGoal(body) {
		return this.http.post('kpi/saveKPIGoal', body);
	}
	getPrincipalsActive() {
		return this.http.get('kpi/listPrincipalActive');
	}

	getListKpiGoal(body) {
		return this.http.post('kpi/listKPIGoal', body);
	}

	getListMetricType(param) {
		return this.http.post('kpi/listMetricType', param);
	}

	ReportDetailPrincipal(body) {
		return this.http.post('kpi/ReportDetailPrincipal', body);
	}
	// get_principle_by_division
	getPrincipleByDivision(param) {
		return this.http.post('get_principle_by_division', param);
	}

	// get GCBM By Division
	getGCBMByDivision(param) {
		return this.http.post('inventory/getFilterOptions', param);
	}

	getGroupByPrincipal(param) {
		return this.http.post('getProductGroupByPrincipalNo', param);
	}

	getBrandByGroup(param) {
		return this.http.post('getProductBrandByGroupNo', param);
	}

	getCategoryByBrand(param) {
		return this.http.post('getProductCategoryByBrandNo', param);
	}

	getModelByCategory(param) {
		return this.http.post('getProductModelByCategoryNo', param);
	}

	// get salesman list
	getSalesman(param) {
		return this.http.post('master/salesman', param);
	}
	//view saleman
	viewSalesman(param) {
		return this.http.post('master/viewsalesman', param);
	}
	// get sales group
	getSalesGroup(param) {
		return this.http.post('master/salesman_group', param);
	}

	getSaleGroupByDivision(param) {
		return this.http.post('get_salegroup_by_division', param);
	}

	//  get customer by client
	getCustomerByClient() {
		return this.http.get('customer_by_client');
	}

	// get seller team
	getSellerTeam() {
		return this.http.get('seller_team');
	}
	getSaleTeamByDivision(param) {
		return this.http.post('get_saleteam_bydivision', param);
	}
	addCustomerToSaleTeam(body) {
		return this.http.post('master/addcustomertosalesman', body);
	}
	// get sale order
	getSaleOrder(param) {
		return this.http.post('sale_order', param);
	}
	getSaleOrderBYID(param) {
		return this.http.post('sale_order/view', param);
	}

	// get saleTeam promotion
	getSaleTeamPromotion(param) {
		return this.http.post('promotion/getPromotionSaleman', param);
	}
	aDDSaleTeam(param) {
		return this.http.post('promotion/saveSaleTeamToPromotion', param);
	}
	removeSaleTeam(param) {
		return this.http.post('promotion/removeSaleTeamFromPromotion', param);
	}

	// save promotion sku
	savePromotionSKU(body) {
		return this.http.post('savePromotionSKU', body);
	}
	getPromotionsSKU(param) {
		return this.http.post('getPromotionSKU', param);
	}
	getCustomerForSKUPromotion(param) {
		return this.http.post('getCustomerForSKUPromotion', param);
	}
	addCustomerToSKUPromotion(param) {
		return this.http.post('addCustomerToSKUPromotion', param);
	}
	removeCustomerFromPromotionSKU(param) {
		return this.http.post('removeCustomerFromPromotionSKU', param);
	}
	getExcitedCustomerSKU(param) {
		return this.http.post('excited_customer_sku', param);
	}
	AddItemsSKU(param) {
		return this.http.post('savePromotionSKU_Items', param);
	}
	getItemPromotionSKU(param) {
		return this.http.get('listPromotionSKU_Items', param);
	}
	updatePromotionSKUItems(body) {
		return this.http.post('updatePromotionSKU_Items', body);
	}
	delectPromotionSKUItem(param) {
		return this.http.post('removePromotionSKU_Items', param);
	}
	delectPromotionSKU(param) {
		return this.http.delete('removePromotionSKU', param);
	}

	// items
	uploadProductImage(param, body) {
		return this.http.postFile('upload/img/'+ param, body);
	}

	userByRoleForSalesman(param) {
		return this.http.post('user_by_role', param);
	}

	addUserSalesman(param) {
		return this.http.post('adduser_to_Salesman', param);
	}

	// copy promotion
	copyPromotion(param) {
		return this.http.post('promotion/copy', param);
	}
	copyPromotionSKU(param){
		return this.http.post('copyPromoSKU', param);
	}

	// update promotion group
	updatePromotionItemGroup(param) {
		return this.http.post('promotion/updatePromoGroup', param);
	}

	updateLimitedSalesManPromotion(param) {
		return this.http.post('updateLimitePromotion', param);
	}

	activatePromotionSKU(param) {
		return this.http.post('activatePromoSKU', param);
	}

	modifyCustomer(param) {
		return this.http.post('modify_customer', param);
	}

	removeaAllCustomer(param) {
		return this.http.post('removeallcustomer', param);
	}

	// filter products
	getInventoryFilterPGBCM(param) {
		return this.http.post('filter_inventory', param);
	}

	renewUserPassword(param) {
		return this.http.post('user/change_password', param);
	}

	getProvices(param) {
		return this.http.post('web_province', param);
	}
	getDistricts(param) {
		return this.http.post('district', param);
	}
	getCommunes(param) {
		return this.http.post('commune', param);
	}

	getFilterLocation(param) {
		return this.http.post('get_filter_location', param);
	}

	getBusinessTypeAndCustomer(param) {
		return this.http.post('get_business_type_customer_type', param);
	}

	getBranchsByDivision(param) {
		return this.http.post('get_branchby_division_kpi', param);
	}

	addExtendDatePromotion(param) {
		return this.http.post('promotion/extendtodate', param);
	}

	copyKpi(param) {
		return this.http.post('kpi/copyKPIGoal', param);
	}
	
	getDashboard(param) {
		return this.http.post('dashboard/card-1', param);
	}
	getDashboardTotalInvoiceBySalemn(param) {
		return this.http.post('dashboard/card-4', param);
	}
	getDashBoardTotalTargetAndInvoiceBySaleTheam(param) {
		return this.http.post('dashboard/card-5', param);
	}
	getDashBoardSaleGroupInvoiceThisMonth(param) {
		return this.http.post('dashboard/get_salegroup', param);
	}

	// get ar_invoice
	getInvoice(param) {
		return this.http.post('invoice_order', param);
	}
	getViewInvoice(param) {
		return this.http.post('invoice_order/view', param);
	}

	// api saleman
	saveSaleman(param) {
		return this.http.post('master/saleman/save', param);
	}
	editSaleman(param, body) {
		return this.http.put('master/saleman/edit', param, body);
	}
	getSalemanByID(param) {
		return this.http.post('master/saleman/view', param);
	}
	getSaleGroupByDivisionIsNotArray(param) {
		return this.http.post('get_salegroup_by_division_notarray', param);
	}
	getAllBranches() {
		return this.http.get('get_branch');
	}

	// get warehouse
	getWarehouseByUserDivision(param) {
		return this.http.post('get_warehouse', param);
	}

	// holiday api
	getHoliday(param) {
		return this.http.post('getpublicholiday', param);
	}
	saveHoliday(param) {
		return this.http.post('createpublicholiday', param);
	}
	updateHoliday(param, body) {
		return this.http.put('updatepublicholiday', param, body);
	}
	viewHoliday(param){
		return this.http.get('viewpublicholiday', param);
	}
	deleteHoliday(param) {
		return this.http.delete('deletepublicholiday', param);
	}
	checkWithRotinePlan(param){
		console.log(param);
		return this.http.post('checkConfirmWithRotinePlan',param);
	}

	// dayOff api
	getDayOff() {
		return this.http.get('listdayoff');
	}
	saveDayOff(param) {
		return this.http.post('createdayoff', param);
	}

	// get total active customer by Metric Type\
	getTotalActiveCustomerByMetricType(param) {
		return this.http.post('get_activecustomer_metric_type', param);
	}

	// api routing plan
	getRoutingPlanList(param) {
		return this.http.post('ListRoutinePlan', param);
	}
	createRoutinePlan(param) {
		return this.http.post('CreatRoutinePlan', param);
	}
	updateRoutinePlan(param, body) {
		return this.http.put('UpdateRoutinePlan', param, body);
	}
	getRoutinePlanByID(param) {
		return this.http.get('ViewRoutinePlan', param);
	}
	getDayOffRoutinePlan(param) {
		return this.http.get('GetdayoffRoutineplan', param);
	}
	updateUpdatedayoffRoutineplan(param, body) {
		return this.http.put('UpdatedayoffRoutineplan', param, body);
	}
	deleteRoutinePlan(param) {
		return this.http.delete('DeleteRoutinePlan', param);
	}
	getCustomerRoutineplan(param) {
		return this.http.post('get_customer_routinePlan', param);
	}
	setCustomerRoutinePlan(param){
		return this.http.post('save_customer_routineplan', param);
	}

	// customer Target
	getCustomerTarget(param) {
		return this.http.post('get/customer_target', param);
	}
	createCustomerTarget(param) {
		return this.http.post('save/customer_target', param);
	}
	deleteCustomerTarget(param) {
		return this.http.get('delete/customer_target', param);
	}
	addCustomerTargetDetail(param) {
		return this.http.post('add/customer_target_detail', param);
	}
	getCustomerTargetDetail(param) {
		return this.http.post('get/customer_target_detail', param);
	}
	getCustomerByID(param) {
		return this.http.get('get/customer_target_byId', param);
	}

	// import customer target
	verifyImportCustomerTarget(param) {
		return this.http.post('verify_customer_target', param);
	}
	importCustomerTarget(param) {
		return this.http.post('import_customer_target', param);
	}

	// RoutinePlan
	importCustomerRoutinePlan(param) {
		return this.http.post('import_customer_routineplan', param);
	}
	modifyCustomerRoutinePlan(param) {
		return this.http.post('Routine_Modify_Customer', param);
	}
	importCustomerRoutinePlan2(param) {
		return this.http.post('Routine_Import_Customer', param);
	}
	copyRoutinePlan(param) {
		return this.http.post('copy_routineplan', param);
	}

	// Report
	getReportPromotion(param) {
		return this.http.post('report/promotion', param);
	}
	getReportPromotionDetail(param) {
		return this.http.post('report/promotion/detail', param);
	}

	// Role
	saveRole(param) {
		return this.http.post('create_update_role', param);
	}
	listRole(param) {
		return this.http.post('list_role', param);
	}
	deleteRole(param) {
		return this.http.post('delete_role', param);
	}
	
	getReportPrincipal(param){
		return this.http.post('reportByPrincipal', param);
	}
	
	getReportPrincipalWithBrand(param){
		return this.http.post('reportByPrincipalWithBrand', param);
	}

	getBusinessType(param) {
		return this.http.post('get_bussiness_type_user', param);
	}
	getCustomerType(param) {
		return this.http.post('get_customer_type_user', param);
	}
	getPrincipalType(param) {
		return this.http.post('get_principal_type_user', param);
	}
	getReportSaleByType(param) {
		return this.http.post('report/reportSale', param);
	}
	getReportCustomerByPrincipal(param) {
		return this.http.post('reportSelectOnCustomerByPrincipal', param);
	}
	saveKpiCopyAs(param) {
		return this.http.post('kpi/copy_as', param);
	}
	getModuleFunction(param) {
		return this.http.get('get_module', param);
	}
	addModuleFunction(param) {
		return this.http.post('add_module_role', param);
	}

	getSaleGroupInCustomer() {
		return this.http.get('get_sale_group_customer');
	}

	updateProductSaleStatus(param) {
		return this.http.post('inventory/update_sale_status', param);
	}
	getBankEndRoleByClient(param) {
		return this.http.post('filter_role_by_client', param);
	}
	getDivisionByClient(param) {
		return this.http.post('get_division_by_client', param);
	}

	// collection
	getCollection(param){
		return this.http.post('collection/list', param);
	}
	getCreateCollection(param) {
		return this.http.post('collection/create', param);
	}
	getBankInfo(param) {
		return this.http.post('collection/getmasterdata', param);
	}
	getSalemanAR(param){
		return this.http.post('collection/getsaleman', param);
	}
	getCollectionInvoices(param) {
		return this.http.post('collection/get_invoice', param);
	}
	gernerateCollectionID(param) {
		return this.http.post('collection/generateid', param);
	}
	getViewCollection(param) {
		return this.http.post('collection/view', param);
	}
	deleteCollection(param) {
		return this.http.post('collection/delect', param);
	}
    getLogCollection(param){
        return this.http.post('collection/log', param);
    }

	// create product
	createProduct(param, image :File) {
		return this.http.postWithImage('product/create', param, image);
	}
	delectProduct(param) {
		return this.http.post('product/delect', param);
	}
	getProductGroup(param) {
		return this.http.post('product/get_group', param);
	}
	getProductModel(param) {
		return this.http.post('product/get_model', param);
	}
	getProductBrand(param) {
		return this.http.post('product/get_brand', param);
	}
	getProductCategory(param) {
		return this.http.post('product/get_category', param);
	}
	getProductPrinciple(param) {
		return this.http.post('product/get_principle', param);
	}
	getProductClass(param) {
		return this.http.post('product/get_class', param);
	}

	// create customer
	postCustomer(param, image :File) {
		return this.http.postWithImage('customer/create', param, image);
	}
	removeCustomer(param) {
		return this.http.post('customer/delete', param);
	}
	getPricingLevel(param) {
		return this.http.post('get_pricing_level', param);
	}
	getFrequencyTypeByDivision(param) {
		return this.http.post('get_frequency_type', param);
	}
	getBusinessTypeByDivision(param) {
		return this.http.post('get_business_type', param);
	}
	getCustomerTypeByDivision(param) {
		return this.http.post('get_customer_type', param);
	}
	createCustomerShippingAddress(param) {
		return this.http.post('customer/createShoppingAddress', param)
	}
	getCustomerShippingAddress(param) {
		return this.http.post('customer/getShippingAddress', param)
	}
	delectShippingAddress(param) {
		return this.http.post('customer/delectShippingAddress', param)
	}

	// create non working date
	createNonWorkingDay(param) {
		return this.http.post('non-working-date/create', param)
	}
	getNonWorkingDay(param) {
		return this.http.post('non-working-date/get', param)
	}
	deleteNonWorkingDay(param) {
		return this.http.post('non-working-date/delect', param)
	}

	// Get SaleTeam BY SaleGroup
	getSaleTeamBySaleGroup(param) {
		return this.http.post('saleTeam/by-salegroup', param)
	}

	// Customer Credit
	getCustomerCredit(param) {
		return this.http.post('customer/customer-credit', param)
	}
	createCustomerCredit(param) {
		return this.http.post('customer-credit/create', param)
	}
	deleteCustomerCredit(param) {
		return this.http.post('customer-credit/delete', param)
	}
	getTerm(param) {
		return this.http.post('get-term', param)
	}

	// Routain Plan Set Multi Num Visited Date
	setMultiNumberVisitedDateRoutinePlan(param) {
		return this.http.post('multipleupdatebySalegroupandsaleteam', param)
	}

	// Report Routine Plan
	getReportRoutinePlanByMonth(param) {
		return this.http.post('routine-plan/report/by-summery', param)
	}
	getReportRoutinePlanBySaleGroup(param) {
		return this.http.post('routine-plan/report-summery/by-salegroup', param)
	}
	getReportRoutinePlanBySaleTeam(param) {
		return this.http.post('routine-plan/report-summery/by-saleteam', param)
	}
	getReportRoutinePlanSaleTeamByDate(param) {
		return this.http.post('routine-plan/report/by-date', param)
	}
    getReportRoutinePlanByCustomers(param) {
		return this.http.post('routine-plan/report/by-customer', param)
	}

	// Competitor
	getListCompetitor(param) {
		return this.http.post('competitor/index', param)
	}
	createCompetitor(body, image :File) {
		return this.http.postWithImage('competitor/create', body, image);
	}
	delectCompetitor(param) {
		return this.http.post('competitor/delect', param)
	}
	getCompotitorByID(param) {
		return this.http.post('competitor/view', param)
	}
	addItemsCompetitor(param) {
		return this.http.post('competitor/add-item', param)
	}
	getCompetitorDetail(param) {
		return this.http.post('competitor/add-item/list', param)
	}
	deleteCompetitorDetail(param) {
		return this.http.post('competitor/add-item/delete', param)
	}
	getActiveCompetitor(param) {
		return this.http.post('competitor/active', param)
	}
    getBranchCompetitor(param) {
		return this.http.post('competitor/branch', param)
	}
    getSaleTeamCompetitor(param) {
		return this.http.post('competitor/saleTeam', param)
	}
    getSaleGroupCompetitor(param) {
		return this.http.post('competitor/saleGroup', param)
	}
    postBranchCompetitor(param) {
		return this.http.post('competitor/add/branch', param)
	}
    postSaleTeamCompetitor(param) {
		return this.http.post('competitor/add/saleTeam', param)
	}
    postSaleGroupCompetitor(param) {
		return this.http.post('competitor/add/saleGroup', param)
	}

	//psku
	getpsku(param){
		return this.http.post('psku/list', param)
	}
	createpsku(param){
		return this.http.post('psku/createupdate', param)
	}
	getviewpsku(param) {
		return this.http.get('psku/view', param)
	}
	deletepsku(param){
		return this.http.post('psku/delete', param)
	}
	getpskudetail(body) {
		return this.http.post('pskudetail/list', body);
	}
	createpskudetail(body) {
		return this.http.post('pskudetail/create', body);
	}
	deletepskudetail(body) {
		return this.http.post('pskudetail/delete', body);
	}
	viewpskudetail(body) {
		return this.http.post('pskudetail/view', body);
	}
	
	// Customer KPI
	getCustomerList(param) {
		return this.http.post('customer-kpi/index', param)
	}
	createCustomerKPI(param, image :File) {
		return this.http.postWithImage('customer-kpi/create', param, image)
	}
	deleteCustomerKPI(param) {
		return this.http.post('customer-kpi/delete', param)
	}
	viewCustomerKPI(param) {
		return this.http.post('customer-kpi/view', param)
	}
    getAllCustomerKpi(param){
        return this.http.post('customer-kpi/list_customer', param)
    }
    setCustomerKpi(param){
        return this.http.post('customer-kpi/set_customer', param)
    }

	// POSM
	getPOSMList(param) {
		return this.http.post('posm/index', param)
	}
	createPOSM(param, images:File) {
		return this.http.postWithMultiImage('posm/create', param, images)
	}
	deletePOSM(param) {
		return this.http.post('posm/delete', param)
	}
	viewPOSM(param) {
		return this.http.post('posm/view', param)
	}
    getCustomerPosm(param){
        return this.http.post('posm/get-customer', param)
    }
    setCustomerPosm(param){
        return this.http.post('posm/set-customer', param)
    }

	// Presentation
	getPresentationList(param) {
		return this.http.post('presentation/index', param)
	}
	createPresentation(param, images:File) {
		return this.http.postWithMultiImage('presentation/create', param, images)
	}
	deletePresentation(param) {
		return this.http.post('presentation/delete', param)
	}
	viewPresentation(param) {
		return this.http.post('presentation/view', param)
	}
    saveCustomerPresentation(param){
        return this.http.post('presentation/save/customer', param)
    }
    saveSaleGroupPresentation(param){
        return this.http.post('presentation/save/sale-group', param)
    }
    saveSaleTeamPresentation(param){
        return this.http.post('presentation/save/sale-team', param)
    }
    getCustomersPresentation(param){
        return this.http.post('presentation/customer', param)
    }
    getSaleGroupsPresentation(param){
        return this.http.post('presentation/salegroup', param)
    }
    getSaleTeamsPresentation(param){
        return this.http.post('presentation/saleteam', param)
    }

	// Report Product Distribution 
	printProductDistribution(param) {
		return this.http.post('ReportPsku', param)
	}

	// copy role
	copyRole(param) {
		return this.http.post('CopyModuleRole', param)
	}

	// collector
	getListCollector(param) {
		return this.http.post('collector/index', param)
	}
	createCollector(body) {
		return this.http.post('collector/create', body);
	}
	delectCollector(param) {
		return this.http.post('collector/delete', param)
	}
	getCollectorByID(param) {
		return this.http.post('collector/view', param)
	}
	getCollectorByDivision(param) {
		return this.http.post('collector/by-division', param)
	}
	postApprovedAndUnApproved(param) {
		return this.http.post('collection/approvedUnApproved', param);
	}

	getReportCustomer(param) {
		return this.http.post('report/customer', param);
	}

	getKpiPromotion(param) {
		return this.http.post('kpi/get-promotion', param);
	}

	deletedAllRoutinePlan(param) {
		return this.http.post('deleteAllRoutinePlan', param);
	}

	//Customer SKU getcustomer
	getCustomerSku(param) {
		return this.http.post('customersku/getcustomer', param);
	}
	getListCustomerSku(param) {
		return this.http.post('customersku/list', param);
	}
	createCustomerSku(param) {
		return this.http.post('customersku/create', param);
	}
	getviewCustomersku(param) {
		return this.http.get('customersku/view', param)
	}
	deletedCustomersku(param) {
		return this.http.post('customersku/delete', param);
	}
	getShippingAddress(param) {
		return this.http.post('customersku/getshippingaddress', param);
	}
	addEditCustomerSummary(param) {
		return this.http.post('customersku/addeditcustomersummary', param);
	}
	listCustomerSummary(param) {
		return this.http.post('customersku/listsummary', param);
	}
	listCustomerDetail(param) {
		return this.http.post('customersku/listdetail', param);
	}
	addEditCustomerDetail(param) {
		return this.http.post('customersku/addeditcustomerdetail', param);
	}
	viewReportCustomerSKU(param) {
		return this.http.post('customersku/reportcustomersku', param);
	}
	
	// Zone
	getListZone(param) {
		return this.http.post('zone/list', param);
	}
	createUpdateZone(param) {
		return this.http.post('zone/create_update', param);
	}
	deleteZone(param) {
		return this.http.post('zone/delete', param);
	}
	getCustomersZone(param) {
		return this.http.post('zone/customer_zone', param);
	}
	getViewZone(param) {
		return this.http.post('zone/view', param);
	}
	setCustomerZone(param) {
		return this.http.post('zone/set_customer_zone', param);
	}
	getZoneByDivision(param) {
		return this.http.post('zone/zone-bydivision', param);
	}

	// Notification
	getNotification(param) {
		return this.http.post('notification/list', param);
	}
	deletedNotification(param) {
		return this.http.post('notification/deleted', param);
	}
	createNotification(param) {
		return this.http.post('notification/create', param);
	}
	viewNotification(param) {
		return this.http.post('notification/view', param);
	}
	viewSalemanNotification(param) {
		return this.http.post('notification/saleman', param);
	}
	resendNotification(param) {
		return this.http.post('notification/resend', param);
	}

	// Report
	getCountStockBySalesman(param) {
		return this.http.post('report/count-stock/by-saleman', param);
	}
	getCountStockByItems(param) {
		return this.http.post('report/count-stock/by-item', param);
	}
	getCountStockByCustomer(param) {
		return this.http.post('report/count-stock/by-customer', param);
	}

	getFilterItemsElement(param) {
		return this.http.post('report/count-stock/filter-item', param);
	}

	getCustomersBySaleTeam(param) {
		return this.http.post('report/customer-bysaleteam', param);
	}
	getBusinessTypeByCustomerType(param) {
		return this.http.post('filter/get-business-type', param);
	}

	// CUSTOMER KPI
	getCustomerKpiByCustomer(param) {
		return this.http.post('report/coustomer-kpi/by-customer', param);
	}
	getCustomerKpiByKpi(param) {
		return this.http.post('report/coustomer-kpi/by-kpi', param);
	}
	getCustomerKpiByDetail(param) {
		return this.http.post('report/coustomer-kpi/by-detail', param);
	}
	getCustomerKpiCustomerbyKPI(param) {
		return this.http.post('report/coustomer-kpi/customer-by-kpi', param);
	}

	getCustomerKpiActive(param) {
		return this.http.post('customer-kpi/active', param);
	}

	// Report POSM
	getPOSMByCustomer(param) {
		return this.http.post('report/posm/by-customer', param);
	}
	getPOSMByPOSM(param) {
		return this.http.post('report/posm/by-posm', param);
	}
	getPOSMByDetail(param) {
		return this.http.post('report/posm/by-detail', param);
	}
	getPOSMByCustomerPOSM(param) {
		return this.http.post('report/posm/by-customer-posm', param);
	}
	getActivePOSM(param) {
		return this.http.post('posm/get-active-posm', param);
	}

	// Report Competitor
	getCompetitorByCompetitor(param) {
		return this.http.post('report/competitor/by-competitor', param);
	}
	getCompetitorByLastVisitedDate(param) {
		return this.http.post('report/competitor/by-lastupdate', param);
	}
	getCompetitorByDetail(param) {
		return this.http.post('report/competitor/by-detail', param);
	}

	// Report Presentation
	getPresentationBySummery(param) {
		return this.http.post('report/presentation/by-summary', param);
	}
	getPresentationByDetail(param) {
		return this.http.post('report/presentation/by-detail', param);
	}
	getPresentationActive(param) {
		return this.http.post('presentation/active', param);
	}

	// Discount
	getDiscount(param) {
		return this.http.post('discount/index', param); 
	}
	createDiscount(body, discountImage :File) {
		return this.http.postWithImage('discount/new', body, discountImage);
	}
    viewDiscount(param) {
        return this.http.post('discount/view', param);
    }
    activateDiscount(param) {
        return this.http.post('discount/activate', param);
    }
    copyDiscount(param) {
        return this.http.post('discount/copy', param);
    }
    deleteDiscount(param) {
        return this.http.post('discount/delete', param);
    }
    listCustomerDiscount(param) {
        return this.http.post('discount/customer', param);
    }
    addCustomerDiscount(param){
        return this.http.post('discount/add-removecustomer', param);
    }
    removeAllCustomer(param) {
        return this.http.post('discount/removeall-customer', param);
    }
    listDiscountBranch(param) {
        return this.http.post('discount/branch', param);
    }
    addRemoveDiscountBranch(param) {
        return this.http.post('discount/add-removebranch', param);
    }
    listDiscountGroup(param) {
        return this.http.post('discount/group', param);
    }
    addRemoveDiscountGroup(param) {
        return this.http.post('discount/add-removegroup', param);
    }
    listsallerDiscount(param) {
        return this.http.post('discount/saller', param);
    }
    addRemoveSallerDiscount(param) {
        return this.http.post('discount/add-removesaller', param);
    }
    importCustomerDiscount(param) {
        return this.http.post('discount/import-customer', param);
    }
    addDiscountDetail(param){
        return this.http.post('discount/add-discount', param);
    }
    getDiscountDetail(param) {
        return this.http.post('discount/get-detail-discount', param);
    }
    removeDetailDiscount(param){
        return this.http.post('discount/remove-detail-discount', param);
    }
    extendDateDiscount(param){
        return this.http.post('discount/extendDate-discount', param);
    }

    importkpiByWhole(param){
        return this.http.post('kpi/import-bywhole', param);
    }
    verifykpiByWhole(param){
        return this.http.post('kpi/verify-bywhole', param);
    }

    // Discount Report
    getReportDiscountBySummarize(param){
        return this.http.post('report/discount/by-summarize', param);
    }
    getReportDiscountByDetail(param){
        return this.http.post('report/discount/by-detail', param);
    }

    // get special permission key
    getSpecialPermissionKey(param){
        return this.http.post('role/get-specialkey', param);
    }
    saveSpecialPermissionKey(param){
        return this.http.post('role/save-specialkey', param);
    }
    removeSpecialPermissionKey(param){
        return this.http.post('role/remove-specialkey', param);
    }

    // report customer target
    getCustomerTargetReport(param){
        return this.http.post('report/customer-target', param);
    }
    getCustomerTargetByPriciple(param) {
        return this.http.post('report/customer-target/principle', param);
    }

    // import data request
    getDataRequest(url,param){
        return this.http.post(url, param);
    }

    saleOrderSetManualStatus(param){
        return this.http.post('sale_order/set-manual-status', param);
    }
}