import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-progressing-bar',
  templateUrl: './progressing-bar.component.html',
  styleUrls: ['./progressing-bar.component.scss']
})
export class ProgressingBarComponent implements OnInit {

	@Input() public value;
	@Output() isCloseModel: EventEmitter<any> = new EventEmitter();
	isLoading = false;
	constructor(
		public activeModal: NgbActiveModal
	) {
	}

	ngOnInit() {
		if(this.value < 100){
			this.isLoading = true
		}
		
	}

	closeModel() {
		this.activeModal.dismiss();
		this.isCloseModel.emit(true);
	}
}
