import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router} from '@angular/router';
import { Observable, TimeoutError } from 'rxjs';
import { Globals } from '../services/globals';

@Injectable()
export class RoleGuard implements CanActivate{

	private canAccess: boolean = false;
	private userPermissions : any = [];
	constructor(private _router: Router) { }

	canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
		
		var userPermissions = JSON.parse(localStorage.getItem('user_permissions'));
		if (userPermissions.length>0) {
			var menuName = [];
			userPermissions.forEach(m => {
				m.Function.forEach(mm => {
					menuName.push(mm.FunctionName)
				});
			});
			var isHave = menuName.includes(next.data.menu)
			if (isHave) {
				return true
			} else {
				this._router.navigate(['/error/404'])
				return false
			}
		} else {
			this._router.navigate(['/error/404'])
			return false
		}
	}
}