import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
import { DataService } from '../../../../shared/services/dataService';
import { HelperService } from '../../../../shared/services/helperService';
import { TranslateService } from '@ngx-translate/core';

@Component({
	selector: 'app-customerkpidialog-modal',
	templateUrl: './customerdialog-modal.component.html',
	styleUrls: ['./customerdialog-modal.component.scss'],
})
export class CustomerDialogModalComponent implements OnInit {

    @Input() type
    @Input() param
    @Input() url
	@Output() close: EventEmitter<any> = new EventEmitter();
   
    loading: boolean = false;
	customers: any = [];
	numPage: string = "1";
	searchTerm: string = "";
	itemsPerPageList: any = [];
	itemsPerPage: string;
	totalItems: number;
	ItemsSelected: any = [];
	selectAll: boolean=false

	constructor(
		public activeModal: NgbActiveModal,
		private dataService: DataService,
		private translate: TranslateService,
		private helperService: HelperService,
		private router: Router
	) {

	}

	ngOnInit() {
		this.itemsPerPageList = this.helperService.getItemsPerPageList();
		this.itemsPerPage = this.itemsPerPageList[0].value;
		this.get();
	}

	get() {
		this.loading=true
        this.param.perPage = this.itemsPerPage
		this.param.page = this.numPage
        this.param.searchTerm = this.searchTerm
        this.param.action = this.type
		this.dataService.getDataRequest(this.url[0], this.param)
            .subscribe(
            result => {
                this.loading=false
                var customers = result.data
                this.customers = customers.map(cus => {
                    cus.selected=false 
                    return cus
                })
                this.totalItems = result.totalRecord
            },
            error => {
                this.loading=false
                if (error.statusText === 'Unauthorized') {
                    return this.router.navigate(['/login']);
                }
            }
		);
	}
	
	onPageChange(e) {
		this.numPage = e;
		this.selectAll = false
		this.get();
	}

	onChangePageSize() {
		this.get();
	}

	onSearch() {
        this.numPage = "1"
        this.get()
	}

	submit() {
        var customers = this.customers.filter(cus => cus.selected==true)
        if(customers.length==0){
            this.helperService.getTranslateAlertSweetResultNotTimer(
                'warning', 
                this.translate.instant('alert.warning'), 
                "pls select some customer."
            );
            return;
        }
        this.param.customersNo = customers.map(item => { return item.CustomerNo })
		this.dataService.getDataRequest(this.url[1], this.param)
		  	.subscribe(
				result => {
					this.get()
				},
				error => {
                    this.helperService.handleErrorResponse(error)
				}
		);
	}

	setAll() {
		if (this.selectAll){
			this.customers.map(m => {
				m.selected=true 
				return m
			})
		} else {
			this.customers.map(m => {
				m.selected=false 
				return m
			})
		}
	}

	closeModel() {
        this.close.emit(true);
	}
}
