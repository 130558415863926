import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { HelperService } from '../../../shared/services/helperService';
import { DataService } from '../../../shared/services/dataService';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-progressing-bar-2',
  templateUrl: './progressing-bar.component.html',
  styleUrls: ['./progressing-bar.component.scss']
})
export class ProgressingBarComponent implements OnInit {

    @Input() public totalItems;
    @Input() public params;
    @Input() public url;
	@Output() passDataBack: EventEmitter<any> = new EventEmitter();
	isLoading = false;
    sumPercentage: number = 0
    isClose: boolean = false
    msg: any []
	constructor(
		public activeModal: NgbActiveModal,
        private helperService: HelperService,
        private dataService: DataService,
        private translate: TranslateService,
        private router: Router
	) {
	}

	async ngOnInit() {
        var perPage = this.helperService.getNumberPageExport()
        var numPage = Math.ceil(this.totalItems / perPage)
        var numberProgress = 0
        var data = []
        this.params.perPage = perPage
        for (var i = 1; i <= numPage; i++) {
            if(this.isClose){return;}
            this.params.page = i
            this.isLoading = true
            var respon = await this.dataService.getDataRequest(this.url, this.params).toPromise().then(res => res).catch(err => err);
            if (!respon.ok && respon.ok != undefined) {
                if (respon.statusText === 'Unauthorized') {
                    return this.router.navigate(['/login']);
                }
                this.helperService.getTranslateAlertSweetResult(
                    'error',
                    this.translate.instant('alert.error'), 
                    respon.statusText
                );
                this.msg = respon
                console.log(this.msg)
                this.isLoading = false
                numberProgress = 0
                this.sumPercentage = 0
                break;
            } else  {
                numberProgress += respon.data.length
                this.sumPercentage = Math.floor((numberProgress*100) / this.totalItems)
                data=data.concat(respon.data)
            }
        }

        if (this.sumPercentage == 100) {
            this.isLoading = false
            this.passDataBack.emit(data)
        }
	}

	closeModel() {
        this.isClose = true
		this.activeModal.dismiss();
	}
}
