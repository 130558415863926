import { Directive, Input, OnInit, ElementRef } from "@angular/core";

@Directive({
    selector: "[actionSpecialKey]",
})
export class ActionSpecialKeyDirective implements OnInit {
    
    @Input("specialKeyType") specialKeyType: string;
    @Input("menuType") menuType: string;

    constructor(private elementRef: ElementRef) {}

    ngOnInit() {
        this.elementRef.nativeElement.style.display = "none";
        this.specialKeyTypePermission();
    }

    specialKeyTypePermission() {
       	var userPermissions = JSON.parse(localStorage.getItem('user_permissions'));
        var seft = this
        var menu = userPermissions.filter(mm => {
            return mm.Function.find(mmm => {
                return mmm.FunctionName==seft.menuType
            })
        }, seft).map(m => {
            return m.Function.find(mm => {
                return mm.FunctionName == seft.menuType
            })
        }, seft).map(m => {
            return m.SpecialKey.find(mm => {
                return mm.SpecialPermissionKey == seft.specialKeyType
            })
        }).filter(m => {
            return m!=undefined
        })
        
        this.elementRef.nativeElement.style.display = menu.length > 0 && menu[0].AccessRight==1 ? "block" : "none";
    }
}
