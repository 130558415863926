import { Injectable } from '@angular/core';
import {Router} from '@angular/router';

@Injectable({
    providedIn: 'root'
})
export class PrintService {

  constructor(private router: Router) { }

  printDocument(url: string, param: any) {
    this.router.navigate(['',
      { outlets: {
        'print': ['print', url]
      }}], { queryParams: {queryParams: JSON.stringify(param)}});
  }

  onDataReady() {
    setTimeout(() => {
      window.print();
      this.router.navigate([{ outlets: { print: null }}]);
    });
  }
}
