
export const loginStyle = {
    backgroundColor: '#26469a',
    logo: '../../../assets/img/longLogo.png'
}

export const mainStyle = {
    companyTitle: "SAPP",
    logo: 'assets/img/logoIcon.png',
    logoDark: 'assets/img/logo-dark.png',
    headerBackgroundColor: '#fff'
}