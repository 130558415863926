import { Component, Output, EventEmitter, OnInit, AfterViewInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { LayoutService } from '../services/layout.service';
import { ConfigService } from '../services/config.service';
import { Constants } from '../../constants/constant';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ChangePassworddialogModalComponent } from '../../shared/dialogs/changePassworddialog-modal/changepassworddialog-modal.component';
import { mainStyle } from '../../shared/template-config/template.config';

@Component({
  selector: "app-navbar",
  templateUrl: "./navbar.component.html",
  styleUrls: ["./navbar.component.scss"]
})
export class NavbarComponent implements OnInit, AfterViewInit {
  currentLang = "en";
  toggleClass = "ft-maximize";
  placement = "bottom-right";
  public isCollapsed = true;
  public style: any = mainStyle
  @Output()
  toggleHideSidebar = new EventEmitter<Object>(); 

  public config: any = {};

  user: any;

  constructor(private modalService: NgbModal, public translate: TranslateService, private layoutService: LayoutService, private configService:ConfigService) {
    const browserLang: string = translate.getBrowserLang();
    translate.use(browserLang.match(/en|km/) ? browserLang : "en");

  }

  ngOnInit() {
    this.config = this.configService.templateConf;
    this.user = JSON.parse(localStorage.getItem(Constants.LOCAL_STORAGE_USER));
    this.translate.use(localStorage.getItem(Constants.LOCAL_STORAGE_USER_LANGUAGE));
  }

  ngAfterViewInit() {
    if(this.config.layout.dir) {
      const dir = this.config.layout.dir;
        if (dir === "rtl") {
          this.placement = "bottom-left";
        } else if (dir === "ltr") {
          this.placement = "bottom-right";
        }
    }
  }


  ChangeLanguage(language: string) {
    this.translate.use(language);
    localStorage.setItem(Constants.LOCAL_STORAGE_USER_LANGUAGE, language);
  }

  ToggleClass() {
    if (this.toggleClass === "ft-maximize") {
      this.toggleClass = "ft-minimize";
    } else {
      this.toggleClass = "ft-maximize";
    }
  }

  Logout(e){
		e.stopPropagation();
		localStorage.removeItem(Constants.LOCAL_STORAGE_USER);
			localStorage.removeItem(Constants.LOCAL_STORAGE_USER_TOKEN);
			localStorage.removeItem(Constants.LOCAL_STORAGE_USER_TOKEN_EXPIRED_AT);
			localStorage.removeItem(Constants.LOCAL_STORAGE_USER_LANGUAGE);
			localStorage.removeItem(Constants.LOCAL_STORAGE_USER_IMAGE);
			localStorage.removeItem(Constants.LOCAL_STORAGE_USER_PERMISSIONS);
			localStorage.removeItem(Constants.LOCAL_STORAGE_USER_REFRESH_TOKEN);
		window.location.reload();
	}


  toggleNotificationSidebar() {
    this.layoutService.emitChange(true);
  }

  toggleSidebar() {
    const appSidebar = document.getElementsByClassName("app-sidebar")[0];
    if (appSidebar.classList.contains("hide-sidebar")) {
      this.toggleHideSidebar.emit(false);
    } else {
      this.toggleHideSidebar.emit(true);
    }
  }

  openChangePassWrod() {
    const modalRef = this.modalService.open(ChangePassworddialogModalComponent, { windowClass: "model-export", size: "lg", backdrop: 'static', centered: true});
    modalRef.componentInstance.isCloseModel.subscribe((isCloseModel) => {
        if (isCloseModel) {
            
        }
    });
}
}
