import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { FullLayoutComponent } from "./layouts/full/full-layout.component";
import { ContentLayoutComponent } from "./layouts/content/content-layout.component";
import { Full_ROUTES } from "./shared/routes/full-layout.routes";
import { PRINT_ROUTES } from "./shared/routes/print-layout.routes";
import { CONTENT_ROUTES } from "./shared/routes/content-layout.routes";
import { AuthGuard } from './shared/auth/auth-guard.service';
import { PrintLayoutComponent } from './pages/print-layout/print-layout.component';

const appRoutes: Routes = [
	{ 
		path: '',
		component: FullLayoutComponent,
		data: { title: 'full Views' },
		canActivate: [AuthGuard],
		children: Full_ROUTES
	},
    { 
        path: 'print',
        outlet: 'print',
        component: PrintLayoutComponent,
        canActivate: [AuthGuard],
        children: PRINT_ROUTES
    },
  	{
		path: '',
		component: ContentLayoutComponent,
		data: { title: 'content Views' },
		children: CONTENT_ROUTES
	},{
		path: 'error',
		loadChildren: () => import('./pages/error/error.module').then(m => m.ErrorModule)
	}
];

@NgModule({
  imports: [RouterModule.forRoot(appRoutes)],
  exports: [RouterModule]
})

export class AppRoutingModule {

}
