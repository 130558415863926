import { 
    Component,
    OnInit, EventEmitter, Output } from '@angular/core';
import { ControlContainer, NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { DataService } from '../../../../../shared/services/dataService';
import { HelperService } from '../../../../../shared/services/helperService';

@Component({
    selector: 'form-filter',
    template: `
        <div class="row">
            <div class="col-md-6">
                <div class="form-group" [ngClass]="{ 'error': isSubmitted && !DivisionName.valid }">
                    <ng-select
                        [items]="divisions"
                        bindLabel="divisionname"
                        bindValue="divisionno"
                        [(ngModel)]="data.divisionNo"
                        name="DivisionName"
                        #DivisionName="ngModel"
                        placeholder="{{ 'form.division'| translate }}"
                        (change)="onChangeText()"
                        required
                    >
                    </ng-select>
                </div>
            </div>
            <div class="col-md-6">
                <div class="form-group" [ngClass]="{ 'error': isSubmitted && !MetricTypes.valid }">
                    <ng-select
                        [items]="metricType"
                        bindLabel="MetricName"
                        bindValue="Id"
                        [(ngModel)]="data.metricTypeNo"
                        name="MetricTypes"
                        #MetricTypes="ngModel"
                        placeholder="{{ 'form.metric.name'| translate }}"
                        (change)="onChangeText()"
                        required
                    >
                    </ng-select>
                </div>
            </div>
        </div>
    `,
    viewProviders: [
        { provide: ControlContainer, useExisting: NgForm }
    ]
  })
export class FormFilterParamComponent implements OnInit  {

    @Output() passBackData: EventEmitter<any> = new EventEmitter();

    data: any = {
        divisionNo: null,
        metricTypeNo: null
    }
    divisions: any = [];
    metricType: any = [];
    isSubmitted: boolean = false;

    constructor(
		public dataService: DataService,
		public helperService: HelperService,
		public router: Router,
        private fg: NgForm
    ) {
        this.fg.ngSubmit.subscribe((data)=>{
            this.isSubmitted = true
        })
    }

    ngOnInit() {
        this.getDivisions()
        this.getMetricTypes()
    }
    
    onChangeText(){
       this.passBackData.emit(this.data)
    }

    getDivisions(){
		this.dataService.getDivisions()
		  	.subscribe(
				result => {
					this.divisions = result.data;
				},
				error => {
					if (error.statusText === 'Unauthorized') {
						return this.router.navigate(['/login']);
					}
                    this.helperService.handleErrorResponse(error)
				}
		);
	}

    getMetricTypes() {
		this.dataService.getListMetricType({
			Status: "1",
			OrderBy: "",
			OrderType: ""
		})
        .subscribe(
            result => {
                this.metricType = result.metricTypes;
            },
            error => {
                if (error.statusText === 'Unauthorized') {
                    return this.router.navigate(['/login']);
                }
                this.helperService.handleErrorResponse(error)
            }
		);
	}
}