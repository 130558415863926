import { Injectable } from '@angular/core';

@Injectable()
export class Globals {
	public static MENU_DASHBOARD = "Dashboard";
	public static MENU_PRODUCT = "Product";
	public static MENU_SALE_MAN = "Saleman";
	public static MENU_CUSTOMER = "Customer";
	public static MENU_Routine_Plan = "Routine Plan";
	public static MENU_PROMOTION = "Promotion";
    public static MENU_RECEIVE_STOCK = "Receive Stock";
	public static MENU_PROMOTION_SKU = "Promotion SKU";
	public static MENU_SALE_ORDER = "Sale Order";
	public static MENU_INVOICE = "Invoice";
	public static MENU_CUSTOMER_TARGET = "Customer Target";
	public static MENU_METRIC_TYPE = "Metric Type";
	public static MENU_KPI_GOAL = "KPI Goal";
	public static MENU_USER = "User";
	public static MENU_HOLIDAY = "Holiday";
	public static MENU_DAY_OFF = "Day Off";
	public static MENU_REPORT_PROMOTION = "Report Promotion";
	public static MENU_DISTRIBUTE_BY_PRINCIPAL = "Distribute by Principal";
	public static MENU_REPORT_SALE = "Report Sale";
	public static MENU_ROLE = "Role";
	public static MENU_COLLECTION = "Collection";
	public static MENU_REPORT_ROUTING_PLAN = "Report Routing Plan";
	public static MENU_PRODUCT_DISTRIBUTE = "Product Distribution";
	public static MENU_COMPETITOR = "Competitor";
	public static MENU_CUSTOMER_KPI = "Customer KPI";
	public static MENU_POSM = "POSM";
	public static MENU_PRESENTATION = "Presentation";
	public static MENU_KPI_REPORT = "KPI Report";
	public static MENU_FINANCIAL = "Financial";
	public static MENU_COLLECTOR = "Collector";
	public static MENU_CUSTOMER_REPORT = "Customer Report";
	public static MENU_CUSTOMER_SKU = "Customer SKU";
	public static MENU_NOTIFICATION = "Notification";
    public static MENU_CREATE_SPECIAL_KEY = "Create Special Key";
	public static MENU_ZONE = "Zone";
	public static MENU_CUSTOMER_COUNT_STOCK = "Customer Count Stock";
	public static MENU_CUSTOMER_KPI_REPORT = "Customer KPI Report";
	public static MENU_POSM_REPORT = "POSM Report";
	public static MENU_COMPETITOR_REPORT = "Competitor Report";
	public static MENU_PRESENTATION_REPORT = "Presentation Report";
	public static MENU_DISCOUNT = "Discount";
    public static MENU_DISCOUNT_REPORT = "Discount Report";
    public static MENU_CUSTOMER_TARGET_REPORT = "Customer Target Report";
    public static MENU_PRICINGLAVEL = "Pricing Lavel";
    public static MENU_WAREHOUSE = "Warehouse";

	public static ACTION_VIEW = "View";
	public static ACTION_ADD = "Add";
	public static ACTION_DELETE = "Delete";
	public static ACTION_EDIT = "Edit";

	// SpecialKey
	public static SPECIAL_KEY_ADD_CUSTOMER = "Add Customer";
	public static SPECIAL_KEY_REMOVE_CUSTOMER = "Remove Customer";
	public static SPECIAL_KEY_ADD_SALEMAN_ROLE = "Add SaleMan Role";
	public static SPECIAL_KEY_EXPORT_EXCEL = "Export Excel";
	public static SPECIAL_KEY_COPY = "Copy";
	public static SPECIAL_SET_DAYOFF = "Set DayOff";
	public static SPECIAL_IMPORT_EXCEL = "Import Excel";
	public static SPECIAL_IMPORT_EXCEL_ADD_CUSTOMER = "Import Excel Add Customer";
	public static SPECIAL_IMPORT_EXCEL_REMOVE_CUSTOMER = "Import Excel Remove Customer";
	public static SPECIAL_ADD_BRANCH = "Add Branch";
	public static SPECIAL_REMOVE_BRANCH = "Remove Branch";
	public static SPECIAL_ADD_SALE_GROUP = "Add Sale Group";
	public static SPECIAL_REMOVE_SALE_GROUP = "Remove Sale Group";
	public static SPECIAL_ADD_SALE_TEAM = "Add Sale Team";
	public static SPECIAL_REMOVE_SALE_TEAM = "Remove Sale Team";
	public static SPECIAL_ACTIVATE = "Activate";
	public static SPECIAL_ADD_ITEMS = "Add Items";
    public static SPECIAL_ADD_DISCOUNT = "Add Discount";
	public static SPECIAL_PRINT = "Print";
	public static SPECIAL_SET_DATE = "Set Date";
	public static SPECIAL_ADD_CUSTOMER_TARGET = "Add Customer Target";
	public static SPECIAL_IMPORT_EXCEL_CUSTOMER_TARGET = "Import Customer Target";
	public static SPECIAL_COPY_AS = "Copy As";
	public static SPECIAL_RESET_PASSWORD = "Reset Password";
	public static SPECIAL_ADD_ROLE = "Add Role";
	public static SPECIAL_ADD_SHIPPING_ADDRESS = "Add Shipping Address";
	public static SPECIAL_ADD_NON_WORKING_DAY = "Add Non Working Day";
	public static SPECIAL_CUSTOMER_CREDIT= "Add Customer Credit";
	public static SPECIAL_UPDATE_NUMBER_VISITED= "Update Number Visited";
	public static SPECIAL_ADD_COMPETITOR_ITEM= "Add Competitor Item";
	public static SPECIAL_ADD_PRODUCT_DISTRIBUTE="Add Product Distribution";
	public static SPECIAL_INTERGRATION = "Intergration";
	public static SPECIAL_APPROVED = "Approved";
	public static SPECIAL_UNAPPROVED  = "UnApproved";
	public static SPECIAL_RECEIVED  = "Received";
	public static SPECIAL_DELETEDALL = "Deleted All";
	public static SPECIAL_ADD_PRODUCT="Add Product";
	public static SPECIAL_RESEND="Resend";
    public static SPECIAL_LOG="Log";
    public static SPECIAL_LOCK="Lock";
    public static SPECIAL_IS_REQUIRE_AND_UPDATE_APP="Is Require and Update App";
    public static SPECIAL_ASSIGN_MODULE="Assign Module";
    public static SPECIAL_ASSIGN_SET_MANUAL_STATUS="Set Manual Status";
    public static SPECIAL_SETUP_PRICE="Setup Price";
}

@Injectable()
export class Globals1 {
	MENU_DASHBOARD = "Dashboard";
    MENU_CREATE_SPECIAL_KEY = "Create Special Key";
	MENU_PRODUCT = "Product";
	MENU_SALE_MAN = "Saleman";
	MENU_CUSTOMER = "Customer";
	MENU_Routine_Plan = "Routine Plan";
	MENU_PROMOTION = "Promotion";
    MENU_RECEIVE_STOCK = "Receive Stock";
	MENU_PROMOTION_SKU = "Promotion SKU";
	MENU_SALE_ORDER = "Sale Order";
	MENU_INVOICE = "Invoice";
	MENU_CUSTOMER_TARGET = "Customer Target";
	MENU_METRIC_TYPE = "Metric Type";
	MENU_KPI_GOAL = "KPI Goal";
	MENU_USER = "User";
	MENU_HOLIDAY = "Holiday";
	MENU_DAY_OFF = "Day Off";
	MENU_REPORT_PROMOTION = "Report Promotion";
	MENU_DISTRIBUTE_BY_PRINCIPAL = "Distribute by Principal";
	MENU_REPORT_SALE = "Report Sale";
	MENU_ROLE = "Role";
	MENU_COLLECTION = "Collection";
	MENU_REPORT_ROUTING_PLAN = "Report Routing Plan";
	MENU_PRODUCT_DISTRIBUTE = "Product Distribution";
	MENU_COMPETITOR = "Competitor";
	MENU_CUSTOMER_KPI = "Customer KPI";
	MENU_POSM = "POSM";
	MENU_PRESENTATION = "Presentation";
	MENU_KPI_REPORT = "KPI Report";
	MENU_FINANCIAL = "Financial";
	MENU_COLLECTOR = "Collector";
	MENU_CUSTOMER_REPORT = "Customer Report";
	MENU_NOTIFICATION = "Notification";
	MENU_ZONE = "Zone";
	MENU_CUSTOMER_COUNT_STOCK = "Customer Count Stock";
	MENU_CUSTOMER_SKU = "Customer SKU";
	MENU_CUSTOMER_KPI_REPORT = "Customer KPI Report";
	MENU_POSM_REPORT = "POSM Report";
	MENU_COMPETITOR_REPORT = "Competitor Report";
	MENU_PRESENTATION_REPORT = "Presentation Report";
	MENU_DISCOUNT = "Discount";
    MENU_DISCOUNT_REPORT = "Discount Report";
    MENU_CUSTOMER_TARGET_REPORT = "Customer Target Report";
    MENU_PRICINGLAVEL = "Pricing Lavel";
    MENU_WAREHOUSE = "Warehouse";

	// Action Key
	ACTION_VIEW = "View";
	ACTION_ADD = "Add";
	ACTION_DELETE = "Delete";
	ACTION_EDIT = "Edit";

	// SpecialKey
	SPECIAL_KEY_ADD_CUSTOMER = "Add Customer";
	SPECIAL_KEY_REMOVE_CUSTOMER = "Remove Customer";
	SPECIAL_KEY_ADD_SALEMAN_ROLE = "Add SaleMan Role";
	SPECIAL_KEY_EXPORT_EXCEL = "Export Excel";
	SPECIAL_KEY_COPY = "Copy";
	SPECIAL_SET_DAYOFF = "Set DayOff";
	SPECIAL_IMPORT_EXCEL = "Import Excel";
	SPECIAL_IMPORT_EXCEL_ADD_CUSTOMER = "Import Excel Add Customer";
	SPECIAL_IMPORT_EXCEL_REMOVE_CUSTOMER = "Import Excel Remove Customer";
	SPECIAL_ADD_BRANCH = "Add Branch";
	SPECIAL_REMOVE_BRANCH = "Remove Branch";
	SPECIAL_ADD_SALE_GROUP = "Add Sale Group";
	SPECIAL_REMOVE_SALE_GROUP = "Remove Sale Group";
	SPECIAL_ADD_SALE_TEAM = "Add Sale Team";
	SPECIAL_REMOVE_SALE_TEAM = "Remove Sale Team";
	SPECIAL_ACTIVATE = "Activate";
	SPECIAL_ADD_ITEMS = "Add Items";
	SPECIAL_PRINT = "Print";
	SPECIAL_SET_DATE = "Set Date";
	SPECIAL_ADD_CUSTOMER_TARGET = "Add Customer Target";
	SPECIAL_IMPORT_EXCEL_CUSTOMER_TARGET = "Import Customer Target";
	SPECIAL_COPY_AS = "Copy As";
	SPECIAL_RESET_PASSWORD = "Reset Password";
	SPECIAL_ADD_ROLE = "Add Role";
	SPECIAL_ADD_SHIPPING_ADDRESS = "Add Shipping Address";
	SPECIAL_ADD_NON_WORKING_DAY = "Add Non Working Day";
	SPECIAL_CUSTOMER_CREDIT= "Add Customer Credit";
	SPECIAL_UPDATE_NUMBER_VISITED= "Update Number Visited";
	SPECIAL_CHANGE_PASSWORD="Change Password";
	SPECIAL_ADD_COMPETITOR_ITEM= "Add Competitor Item";
 	SPECIAL_ADD_PRODUCT="Add Product";
	SPECIAL_INTERGRATION = "Intergration";
	SPECIAL_APPROVED = "Approved";
	SPECIAL_UNAPPROVED  = "UnApproved";
	SPECIAL_RECEIVED  = "Received";
	SPECIAL_DELETEDALL = "Deleted All";
	SPECIAL_ADD_PRODUCT_DISTRIBUTE="Add Product Distribution";
	SPECIAL_RESEND="Resend";
    SPECIAL_ADD_DISCOUNT = "Add Discount";
    SPECIAL_LOG="Log";
    SPECIAL_LOCK="Lock";
    SPECIAL_IS_REQUIRE_AND_UPDATE_APP="Is Require and Update App";
    SPECIAL_ASSIGN_MODULE="Assign Module";
    SPECIAL_ASSIGN_SET_MANUAL_STATUS="Set Manual Status";
    SPECIAL_SETUP_PRICE="Setup Price";
}