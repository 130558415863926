import { Directive, Input, OnInit, ElementRef } from "@angular/core";

@Directive({
    selector: "[actionAccessControl]"
})
export class ActionAccessControlDirective implements OnInit {
    
    @Input("actionAccessType") actionAccessType: string;
    @Input("menuType") menuType: string;

    constructor(private elementRef: ElementRef) {}

    ngOnInit() {
        this.elementRef.nativeElement.style.display = "none";
        this.checkAccessAction();
    }

    checkAccessAction() {
       	var userPermissions = JSON.parse(localStorage.getItem('user_permissions'));
        var seft = this
        var menu = userPermissions.filter(mm => {
            return mm.Function.find(mmm => {
                return mmm.FunctionName==seft.menuType
            })
        }, seft).map(m => {
            return m.Function.find(mm => {
                return mm.FunctionName == seft.menuType
            })
        }, seft)
        
        this.elementRef.nativeElement.style.display = menu[0].Action[this.actionAccessType] ? "block" : "none";
    }
}
