import {
	Component,
	forwardRef,
	HostBinding,
	Input,
	ViewChild,
	OnInit,
	Output, EventEmitter,
	ViewEncapsulation,
	ElementRef,
	AfterViewInit,
	Inject,
	OnDestroy
} from "@angular/core";
import { DOCUMENT } from '@angular/common';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
	selector: "app-image-lightbox",
	templateUrl: "./image-lightbox.component.html",
	styleUrls: ["./image-lightbox.component.css"]
	// encapsulation: ViewEncapsulation.None
})

export class ImageLightboxComponent implements OnInit,AfterViewInit {

	@Input() public ImageURL: any = [];
	Images: any = [];
	slideIndex: number = 1;
	@Output() isCloseModel: EventEmitter<any> = new EventEmitter();

	@ViewChild('myModal', { static: false }) myModal: ElementRef;
	@ViewChild('mySlides', { static: false }) mySlides: ElementRef;
	@ViewChild('Demo', { static: false }) Demo: ElementRef;
	@ViewChild('caption', { static: false }) caption: ElementRef;
	
	constructor(
		@Inject(DOCUMENT) private document: Document,
		public activeModal: NgbActiveModal
	) {
		
	}
	
	ngOnInit() {
		var data = this.ImageURL;
		this.Images = data.filter(m => {
			return m!=""
		})
	}

	ngAfterViewInit() {
		this.showSlides(this.slideIndex);
	}

	closeModal() {
		this.activeModal.dismiss();
	}
	
	plusSlides(n) {
		this.showSlides(this.slideIndex += n);
	}
	  
	currentSlide(n) {
		this.showSlides(this.slideIndex = n);
	}
	  
	showSlides(n) {
		var i;
		var slides: any
		var dots: any
		slides = this.document.getElementsByClassName('mySlides');
		dots = this.document.getElementsByClassName('demo');
		console.log(slides)
		var captionText = this.document.getElementById('caption');
		if (n > slides.length) {this.slideIndex = 1}
		if (n < 1) {this.slideIndex = slides.length}
		for (i = 0; i < slides.length; i++) {
			slides[i].style.display = "none";
		}
		for (i = 0; i < dots.length; i++) {
			dots[i].className = dots[i].className.replace(" active", "");
		}
		slides[this.slideIndex-1].style.display = "block";
		dots[this.slideIndex-1].className += " active";
		captionText.innerHTML = dots[this.slideIndex-1].alt;
	}
}