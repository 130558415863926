import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-edit-input-tool',
  templateUrl: './edit-input.component.html',
  styleUrls: ['./edit-input.component.scss']
})
export class EditInputComponent implements OnInit {

    @Input() data
    @Input() type: string = 'text'
    @Input() width: string = '100'
    @Output() blur: EventEmitter<any> = new EventEmitter();

    isEdit: boolean = false;

    constructor() { }

    ngOnInit() {
    }

    onblur(){
        this.blur.emit(this.data)
    }
}
