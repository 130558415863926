import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { DataService } from '../../../../shared/services/dataService';
import { HelperService } from '../../../../shared/services/helperService';
import { TranslateService } from '@ngx-translate/core';

@Component({
	selector: 'app-salemandialog-modal',
	templateUrl: './salemandialog-modal.component.html',
	styleUrls: ['./salemandialog-modal.component.scss'],
})
export class SalemandialogModalComponent implements OnInit {

	@Input() type
    @Input() param
    @Input() url
	@Output() close: EventEmitter<any> = new EventEmitter()

    loading: boolean = false
	saleGroup: any

	constructor(
		public activeModal: NgbActiveModal,
		private dataService: DataService,
		private translate: TranslateService,
		private helperService: HelperService
	) {}

	ngOnInit() {
		this.getSaleGroup();
	}

	getSaleGroup() {
		this.loading = true
		this.dataService.getDataRequest(this.url[0], this.param)
		.subscribe(
            result => {
                this.loading = false
                this.saleGroup = result
            },
            error => {
                this.loading = false
                this.helperService.handleErrorResponse(error)
        });
	}
	
	onSelectedAll(e) {
		this.saleGroup.map(function(obj) {
			obj.selected = e
			return obj;
		});
	}

	onSelected(e, isCheck) {
		e.selected = isCheck;
	}

	onSubmit() {
		let obj = this.saleGroup.filter(function(e){
			return e.selected == true
		}).map(function(e){
            return e.SellerGroupNo
		});

		if (obj.length == 0) {
			this.helperService.getTranslateAlertSweetResult(
				'error', 
				this.translate.instant('alert.error'),
				this.translate.instant('alert.pls.select.sale.group')
			);
			return;
		}
        this.param.saleGroupNo = obj
        this.dataService.getDataRequest(this.url[1], this.param)
		.subscribe(
            result => {
              this.getSaleGroup()
            },
            error => {
                this.helperService.handleErrorResponseBadRequest(error)
        });
	}

	closeModel() {
		this.close.emit(true);
	}
}
