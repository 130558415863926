import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgxPaginationModule } from 'ngx-pagination';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from "@ngx-translate/core";
import { LoadingModule } from '../../../../shared/loading/loading.module';
import { SaleTeamDialogModalComponent } from './saleTeamdialog-modal.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgbdSortableHeader } from '../saleTeamdialog-modal/saleTeam.sortable.directive';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        NgxPaginationModule,
        LoadingModule,
        NgSelectModule,
        TranslateModule
    ],
    declarations: [SaleTeamDialogModalComponent, NgbdSortableHeader],
    entryComponents: [SaleTeamDialogModalComponent]
})
export class SaleTeamdialogModule { }
