import { Component, OnInit, Input, QueryList, ViewChildren, EventEmitter, Output } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';
import { DataService } from '../../services/dataService';
import { HelperService } from '../../services/helperService';
import { TranslateService } from '@ngx-translate/core';
import {FormGroup, FormBuilder, Validators, FormControl, NgForm} from '@angular/forms';

@Component({
	selector: 'app-changepassworddialog-modal',
	templateUrl: './changepassworddialog-modal.component.html',
	styleUrls: ['./changepassworddialog-modal.component.scss'],
})
export class ChangePassworddialogModalComponent implements OnInit {

	@Output() isCloseModel: EventEmitter<any> = new EventEmitter();
	@Input() Id;
	registerForm: FormGroup;
    submitted: boolean= false;

	constructor(
		public activeModal: NgbActiveModal,
		private dataService: DataService,
		private translate: TranslateService,
		private helperService: HelperService,
		private formBuilder: FormBuilder,
		private router: Router
	) {

	}

	ngOnInit() {
		console.log(this.Id);
		this.registerForm = this.formBuilder.group({
			oldpassword: ['', [Validators.required]],
            password: ['', [Validators.required]],
            confirmPassword: ['', [Validators.required]]
        });
	}

	get oldpassword() {
		return this.registerForm.get('oldpassword');
	}

	get password() {
		return this.registerForm.get('password');
	}

	get confirmPassword() {
		return this.registerForm.get('confirmPassword');
	}

	onSubmit() {
		
		this.submitted = true;
        if (this.registerForm.invalid) {
            return;
		}
		let pass = this.registerForm.value
		let param = {
			old_pass: pass.oldpassword,
			new_pass: pass.password,
			c_password: pass.confirmPassword,
			userid:this.Id
		}

		this.dataService.renewUserPassword(param)
		  	.subscribe(
				result => {
					if(result.status==0) {
						this.helperService.getTranslateAlertSweetResult(
							'success',
							this.translate.instant('alert.success'),
							""
						);
						this.registerForm.reset();
						this.submitted=false;
						if(this.Id!=undefined){
							this.activeModal.dismiss();
						}
					} else {
						this.helperService.getTranslateAlertSweetResult(
							'error', 
							this.translate.instant('alert.error'),
							result.message
						);
					}
				},
				error => {
					if (error.statusText === 'Unauthorized') {
						return this.router.navigate(['/login']);
					}
					this.helperService.getTranslateAlertSweetResult(
						'error', 
						this.translate.instant('alert.error'),
						error.error.data.message
					);
				}
		);
		
	}
	
	closeModel() {
		this.activeModal.dismiss();
		this.isCloseModel.emit(true);
	}
}
