import { NgModule  } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { TranslateModule } from "@ngx-translate/core";
import { NgSelectModule } from '@ng-select/ng-select';
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { UploadFileComponent, OptionUploadParamComponent } from './upload-file.component';
import { DownloadFileComponent } from '../../component/download-file/download-file.component';
import { OffcanvasComponent } from "../../component/offcanvas/offcanvas.component";
import { FormFilterParamComponent as FormFilterImportKPIComponent } from "../../../pages/kpi/kpiGoal/components/form/formFilterParam.component";

@NgModule({
    exports: [
        CommonModule,
        TranslateModule,    
        OffcanvasComponent,
        UploadFileComponent,
        DownloadFileComponent
    ],
    imports: [
        CommonModule,
        NgbModule,
        TranslateModule,
        NgSelectModule,
        FormsModule,
        ReactiveFormsModule
    ],
    declarations: [
        UploadFileComponent, 
        OptionUploadParamComponent,
        DownloadFileComponent, 
        OffcanvasComponent,
        FormFilterImportKPIComponent
    ],
    entryComponents:[
        OffcanvasComponent,
        UploadFileComponent,
        DownloadFileComponent,
        FormFilterImportKPIComponent
    ],
    providers: []
 })
export class UploadFileModule { }
