import { 
    Component, 
    ViewChild, 
    OnInit,
    EventEmitter, 
    Output, 
    ElementRef, 
    AfterViewInit, 
    ComponentFactoryResolver, 
    ViewContainerRef,
    Input, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import * as XLSX from 'xlsx';
import { DataService } from '../../../shared/services/dataService';
import { HelperService } from '../../../shared/services/helperService';
import { MODULE, EXPORT_ACTION_TYPE } from '../../../constants/constant';
import { DataTemplateImportService } from '../../services/dataTemplateImport.service';
import { FormFilterParamComponent as FormFilterImportKPIComponent  } from '../../../pages/kpi/kpiGoal/components/form/formFilterParam.component';

interface BaseFormParamInterface {
    passBackData: any
}

@Component({
    selector: 'app-option-upload-param',
    template: `
            <div style="
                border: 1px solid #c3c3c3;
                padding: 15px;
                border-radius: 3px;
                margin-bottom: 2em;
                margin-top: 2em;
            ">
                <ng-template #content>
                </ng-template>
            </div>
        `
  })
  export class OptionUploadParamComponent implements OnInit, OnDestroy  { 

    @Input() module: any
    @Output() passBackData: EventEmitter<any> = new EventEmitter();
    @ViewChild("content", {read: ViewContainerRef, static: true}) vc: ViewContainerRef;

    constructor(
        public componentFactoryResolver: ComponentFactoryResolver,
        public viewContainerRef: ViewContainerRef
    ) { 
    }

    ngOnInit() {
        this.vc.clear();
        const componentFactory = this.componentFactoryResolver.resolveComponentFactory<BaseFormParamInterface>(this.typeOfComponent(this.module));
        const componentInstance =  this.vc.createComponent(componentFactory);
        componentInstance.instance.passBackData.subscribe(data => {
           this.passBackData.emit(data)
        })
    }

    ngOnDestroy(){
        this.vc.clear();
    }

    typeOfComponent(module){
        var components = {
            "kpi_goal": FormFilterImportKPIComponent,
        }
        return components[module]
    }
}

@Component({
  selector: 'app-upload-file',
  templateUrl: './upload-file.component.html',
  styleUrls: ['./upload-file.component.scss']
})
export class UploadFileComponent implements OnInit, AfterViewInit  {

    @Input() module: any
    @Input() urls: any
    @Input() params: any = {}
    @Input() exportActionType
    @Output() upload = new EventEmitter<any>()

    isModify: boolean = true
    countUpload: any = 0
    isClose: boolean = false

    msg: any = []
    modulesUseParamater: any  = {}
    paramItems: any
    numProgressing: Number = 0

    constructor(
		public dataService: DataService,
		public helperService: HelperService,
		public router: Router,
        public dataTemplateImportService: DataTemplateImportService,
        public componentFactoryResolver: ComponentFactoryResolver,
        public viewContainerRef: ViewContainerRef
    ) {
    }

    @ViewChild('UploadFileInput', { static: false }) UploadFileInput: ElementRef;
    @ViewChild('openImportFile', { static: false }) openImportFile: ElementRef;

    ngOnInit() {
        if(this.exportActionType==EXPORT_ACTION_TYPE.REMOVE_FILE){
            this.isModify = false
        }

        this.modulesUseParamater = {
            'kpi_goal': MODULE.KPI_GOAL
        }
    }

    ngAfterViewInit() {
        this.helperService.openSideBar(this.openImportFile)
    }

    uploadFile(){
        if(this.UploadFileInput.nativeElement.files.length==0)return;
		var file = this.UploadFileInput.nativeElement.files[0];
        var file = this.UploadFileInput.nativeElement.files[0];
        var filename = file.name;
        var extension = filename.split('.').pop();
		var reader = new FileReader();
		var seft = this;
        if (extension == 'xlsx' || extension == 'xls') {
            reader.addEventListener("load", function(){
                var data = reader.result;
                var wb: any;
				var arr = seft.helperService.fixdata(data)
				wb = XLSX.read(btoa(arr), {type: 'base64'});
                data = seft.helperService.XLSXToCSV(wb);
				var tempData = data;

                while(tempData.indexOf('"')>=0)
                {
                    tempData = tempData.substring(tempData.indexOf('"')+1);
					var content = tempData.substring(0,tempData.indexOf('"'));
                    var replacecontent = content.replace(/,/g, "!@#");
                    data = data.replace(content,replacecontent);
					tempData = tempData.substring(tempData.indexOf('"')+1);
				}
              seft.compressData(data);
            }, false);
            if (file) {
                reader.readAsArrayBuffer(file);
            }
        } else if (extension == 'csv') {
            reader.addEventListener("load", function () {
				seft.compressData(reader.result);
            }, false);
            if (file) {
                reader.readAsText(file);
            }
		}
    }

    async compressData(item){
        var lines = item.split("\n");
		lines = lines.filter(e => {
			return e!=""
		})
        let data = []
        switch(this.module) { 
            case MODULE.ROUTING_PLAN:{
                data = this.dataTemplateImportService.getTemplateRoutinePlan(lines)
                break;
            } 
            case MODULE.PROMOTION:{
                data = this.dataTemplateImportService.getTemplatePromotionCustomer(lines)
                break;
            }
            case MODULE.CUSTOMER_TARGET:{
                data = this.dataTemplateImportService.getTemplateCustomerTarget(lines)
                break;
            }
            case MODULE.KPI_GOAL:{
                data = this.dataTemplateImportService.getTemplatekpiGoal(lines).map(d => {
                    d.MetricTypeID = this.paramItems.metricTypeNo
                    d.DivisionNo = this.paramItems.divisionNo
                    return d
                })
                break;
            }
            case MODULE.DISCOUNT:{
                data = this.dataTemplateImportService.getTemplateDiscount(lines);
                break;
            }
            case MODULE.PRESENTATION:{
                data = this.dataTemplateImportService.getTemplatePresentation(lines);
                break;
            }
            case MODULE.POSM:{
                data = this.dataTemplateImportService.getTemplateCustomerPOSM(lines);
                break;
            }
            case MODULE.CUSTOMER_KPI:{
                data = this.dataTemplateImportService.getTemplateCustomerCutomerKpi(lines);
                break;
            }
            default: { 
                break;
            } 
        }
        
        let numProgressing = 0
        this.numProgressing = 0
        var pageSize = 100
        for (let index = 1; index <= Math.ceil((data.length/pageSize)); index++) {
            var dataPass = this.helperService.paginate(data, pageSize, index)
            if(this.module==MODULE.ROUTING_PLAN){
                this.params.customerdata = dataPass
            }else if(this.module==MODULE.PROMOTION){
                this.params.customersList = dataPass
            }else if(this.module==MODULE.CUSTOMER_TARGET){
                this.params.dataItems = dataPass
            }else if(this.module==MODULE.KPI_GOAL){
                this.params.body = dataPass
            }else if(this.module==MODULE.DISCOUNT){ 
                this.params.customersNo = dataPass
            }else if(this.module==MODULE.PRESENTATION){
                this.params.customersNo = dataPass
            }else if(this.module==MODULE.POSM){
                this.params.customersNo = dataPass
            }else if(this.module==MODULE.CUSTOMER_KPI){
                this.params.customersNo = dataPass
            }
            this.msg = []
            if(this.countUpload>=2){this.isModify=true; return;}
            if(this.isClose){return;}
            let url = this.isModify?this.urls[0]:this.urls[1]
            let respon = await this.dataService.getDataRequest(url, this.params).toPromise().then(res => res).catch(err => err);
            if (respon.status!=200&&respon.status!=undefined) {
                if (respon.statusText === 'Unauthorized') {
                    return this.router.navigate(['/login']);
                }
                this.msg = respon.error
                this.numProgressing = 0
                this.isModify = true
                break;
            }else{
                numProgressing += respon.length
                this.numProgressing = Math.floor((numProgressing*100)/data.length)
                if(numProgressing==data.length){
                    this.msg = ["Success"]
                    if(!this.isModify){this.countUpload+=1}
                    if(this.isModify){this.isModify = false; this.countUpload+=1}
                }
            }
        }
    }

    close($event){
       this.isClose = $event
    }
}