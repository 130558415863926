import { Routes } from '@angular/router';
import { AuthGuard } from '../auth/auth-guard.service';
import { RoleGuard } from '../auth/role-guard.service';
import { Globals } from '../services/globals';

//Route for content layout with sidebar, navbar and footer
export const Full_ROUTES: Routes = [
	{
		path: '',
		pathMatch:'prefix',
		redirectTo:'dashboard'
	},
	{
		path: 'dashboard',
		loadChildren: () => import('../../pages/dashboard/dashboard-page.module').then(m => m.DashboardPageModule),
		canActivate: [AuthGuard, RoleGuard],
		data: { menu: Globals.MENU_DASHBOARD }
	},
	{
		path: 'master',
		children: [
			{
				path: 'product',
				loadChildren: () => import('../../pages/master/product/product.module').then(m => m.ProductModule),
				canActivate: [AuthGuard, RoleGuard],
				data: { menu: Globals.MENU_PRODUCT }
			},
			{
				path: 'salesman',
				loadChildren: () => import('../../pages/master/salesman/salesman.module').then(m => m.SalesManModule),
				canActivate: [AuthGuard, RoleGuard],
				data: { menu: Globals.MENU_SALE_MAN }
			},
			{
				path: 'customer',
				loadChildren: () => import('../../pages/master/customer/customer.module').then(m => m.CustomerModule),
				canActivate: [AuthGuard, RoleGuard],
				data: { menu: Globals.MENU_CUSTOMER }
			},{
				path: 'zone',
				loadChildren: () => import('../../pages/master/zone/zone.module').then(m => m.ZoneModule),
				canActivate: [AuthGuard, RoleGuard],
				data: { menu: Globals.MENU_ZONE }
			},{
				path: 'pricing-lavel',
				loadChildren: () => import('../../pages/master/pricinglevel/pricinglavel.module').then(m => m.PricinglavelModule),
				canActivate: [AuthGuard, RoleGuard],
				data: { menu: Globals.MENU_PRICINGLAVEL }
			},{
				path: 'warehouse',
				loadChildren: () => import('../../pages/master/warehouse/warehouse.module').then(m => m.WarehouseModule),
				canActivate: [AuthGuard, RoleGuard],
				data: { menu: Globals.MENU_WAREHOUSE }
			}
		]
	},
	{
		path: 'routing-plan',
		children: [
			{
				path: 'routing-plan',
				loadChildren: () => import('../../pages/routingPlan/routingPlan/routingPlan.module').then(m => m.RoutingPlanModule),
				canActivate: [AuthGuard, RoleGuard],
				data: { menu: Globals.MENU_Routine_Plan }
			}
		]
	},
	{
		path: 'promotion',
		loadChildren: () => import('../../pages/promotion/promotion.module').then(m => m.PromotionModule),
		canActivate: [AuthGuard, RoleGuard],
		data: { menu: Globals.MENU_PROMOTION }
	},
	{
		path: 'promotion-sku',
		loadChildren: () => import('../../pages/promotionSKU/promotionsku.module').then(m => m.PromotionSKUModule),
		canActivate: [AuthGuard, RoleGuard],
		data: { menu: Globals.MENU_PROMOTION_SKU }
	},
	{
		path: 'sale-order',
		loadChildren: () => import('../../pages/saleOrder/saleOrder.module').then(m => m.SaleOrderModule),
		canActivate: [AuthGuard, RoleGuard],
		data: { menu: Globals.MENU_SALE_ORDER }
	},
	{
		path: 'invoice-order',
		loadChildren: () => import('../../pages/invoice/saleOrder.module').then(m => m.SaleOrderModule),
		canActivate: [AuthGuard, RoleGuard],
		data: { menu: Globals.MENU_INVOICE }
	},
	{
		path: 'customer-target',
		loadChildren: () => import('../../pages/customerTarget/cutomerTarget.module').then(m => m.CutomerTargetModule),
		canActivate: [AuthGuard, RoleGuard],
		data: { menu: Globals.MENU_CUSTOMER_TARGET }
	},{
		path: 'discount',
		loadChildren: () => import('../../pages/discount/discount.module').then(m => m.DiscountModule),
		canActivate: [AuthGuard, RoleGuard],
		data: { menu: Globals.MENU_DISCOUNT }
	},{
		path: 'marketing',
		children: [
			{
				path: 'product-distribution',
				loadChildren: () => import('../../pages/marketing/product-distribution/product-distribution.module').then(m => m.ProductDistributionModule),
				canActivate: [AuthGuard, RoleGuard],
				data: { menu: Globals.MENU_PRODUCT_DISTRIBUTE }
			},
			{
				path: 'customer-sku',
				loadChildren: () => import('../../pages/marketing/customer-sku/customer-sku.module').then(m => m.CustomerSKUModule),
				canActivate: [AuthGuard, RoleGuard],
				data: { menu: Globals.MENU_CUSTOMER_SKU }
			},
			{
				path: 'competitor',
				loadChildren: () => import('../../pages/marketing/competitor/competitor.module').then(m => m.CompetitorModule),
				canActivate: [AuthGuard, RoleGuard],
				data: { menu: Globals.MENU_COMPETITOR }
			},{
				path: 'customer-kpi',
				loadChildren: () => import('../../pages/marketing/customerKPI/customerKPI.module').then(m => m.CustomerKPIModule),
				canActivate: [AuthGuard, RoleGuard],
				data: { menu: Globals.MENU_CUSTOMER_KPI }
			},{
				path: 'posm',
				loadChildren: () => import('../../pages/marketing/posm/posm.module').then(m => m.posmModule),
				canActivate: [AuthGuard, RoleGuard],
				data: { menu: Globals.MENU_POSM }
			},{
				path: 'presentation',
				loadChildren: () => import('../../pages/marketing/presentation/presentation.module').then(m => m.PresentationModule),
				canActivate: [AuthGuard, RoleGuard],
				data: { menu: Globals.MENU_PRESENTATION }
			}
		]
	},
	{
		path: 'financial',
		children: [
			{
				path: 'collector',
				loadChildren: () => import('../../pages/financial/collector/collector.module').then(m => m.CollectorModule),
				canActivate: [AuthGuard, RoleGuard],
				data: { menu: Globals.MENU_COLLECTOR }
			},{
				path: 'collection',
				loadChildren: () => import('../../pages/financial/collection/collection.module').then(m => m.CollectionModule),
				canActivate: [AuthGuard, RoleGuard],
				data: { menu: Globals.MENU_COLLECTION }
			}
		]
	},
	{
		path: 'kpi',
		children: [
			{
				path: 'metric-type',
				loadChildren: () => import('../../pages/kpi/metricType/metrictype.module').then(m => m.MetricTypeModule),
				canActivate: [AuthGuard, RoleGuard],
				data: { menu: Globals.MENU_METRIC_TYPE }
			},
			{
				path: 'kpi-goal',
				loadChildren: () => import('../../pages/kpi/kpiGoal/kpiGoal.module').then(m => m.kpiGoalModule),
				canActivate: [AuthGuard, RoleGuard],
				data: { menu: Globals.MENU_KPI_GOAL }
			}
		]
	},
	{
		path: 'report',
		children: [
			{
				path: 'promotion',
				loadChildren: () => import('../../pages/report/promotion/promotion.module').then(m => m.PromotionModule),
				canActivate: [AuthGuard, RoleGuard],
				data: { menu: Globals.MENU_REPORT_PROMOTION }
			},
			{
				path: 'distribution',
				loadChildren: () => import('../../pages/report/distribution/distribution.module').then(m => m.DistributionModule),
				canActivate: [AuthGuard, RoleGuard],
				data: { menu: Globals.MENU_DISTRIBUTE_BY_PRINCIPAL }
			},{
				path: 'sale',
				loadChildren: () => import('../../pages/report/sale/sale.module').then(m => m.SaleModule),
				canActivate: [AuthGuard, RoleGuard],
				data: { menu: Globals.MENU_REPORT_SALE }
			},{
				path: 'routing-plan',
				loadChildren: () => import('../../pages/report/routinePlan/routinePlan.module').then(m => m.RoutinePlanReportModule),
				canActivate: [AuthGuard, RoleGuard],
				data: { menu: Globals.MENU_REPORT_SALE }
			}
			,{
				path: 'kpi-report',
				loadChildren: () => import('../../pages/report/KPI/kpiReport.module').then(m => m.kpiReportModule),
				canActivate: [AuthGuard, RoleGuard],
				data: { menu: Globals.MENU_KPI_REPORT }
			},{
				path: 'customer',
				loadChildren: () => import('../../pages/report/customer/customer.module').then(m => m.CustomerModule),
				canActivate: [AuthGuard, RoleGuard],
				data: { menu: Globals.MENU_CUSTOMER_REPORT }
			},{
				path: 'count-stock',
				loadChildren: () => import('../../pages/report/customer-countStock/customerCountStock.module').then(m => m.CustomerCountStockModule),
				canActivate: [AuthGuard, RoleGuard],
				data: { menu: Globals.MENU_CUSTOMER_COUNT_STOCK }
			},{
				path: 'customer-kpi',
				loadChildren: () => import('../../pages/report/customerKPI/customerKPI.module').then(m => m.CustomerKPIModule),
				canActivate: [AuthGuard, RoleGuard],
				data: { menu: Globals.MENU_CUSTOMER_KPI_REPORT }
			},{
				path: 'posm',
				loadChildren: () => import('../../pages/report/posm/posm.module').then(m => m.POSMModule),
				canActivate: [AuthGuard, RoleGuard],
				data: { menu: Globals.MENU_POSM_REPORT }
			},{
				path: 'competitor',
				loadChildren: () => import('../../pages/report/competitor/competitor.module').then(m => m.CompetitorModule),
				canActivate: [AuthGuard, RoleGuard],
				data: { menu: Globals.MENU_COMPETITOR_REPORT }
			},{
				path: 'presentation',
				loadChildren: () => import('../../pages/report/presentation/presentation.module').then(m => m.PresentationModule),
				canActivate: [AuthGuard, RoleGuard],
				data: { menu: Globals.MENU_PRESENTATION_REPORT }
			},{
				path: 'discount',
				loadChildren: () => import('../../pages/report/discounts/discount.module').then(m => m.DiscountModule),
				canActivate: [AuthGuard, RoleGuard],
				data: { menu: Globals.MENU_DISCOUNT_REPORT }
			},{
				path: 'customer-target',
				loadChildren: () => import('../../pages/report/customerTarget/customerTarget.module').then(m => m.CustomerTargetModule),
				canActivate: [AuthGuard, RoleGuard],
				data: { menu: Globals.MENU_CUSTOMER_TARGET_REPORT }
			}
		]
	},
	{
		path: 'setting',
		children: [
			{
				path: 'user',
				loadChildren: () => import('../../pages/setting/user/user.module').then(m => m.UserModule),
				canActivate: [AuthGuard, RoleGuard],
				data: { menu: Globals.MENU_USER }
			},
			{
				path: 'role',
				loadChildren: () => import('../../pages/setting/role/role.module').then(m => m.RoleModule),
				canActivate: [AuthGuard, RoleGuard],
				data: { menu: Globals.MENU_ROLE }
			},
			{
				path: 'holiday',
				loadChildren: () => import('../../pages/setting/holiday/holiday.module').then(m => m.HolidayModule),
				canActivate: [AuthGuard, RoleGuard],
				data: { menu: Globals.MENU_HOLIDAY }
			},
			{
				path: 'dayoff',
				loadChildren: () => import('../../pages/setting/dayoff/dayoff.module').then(m => m.DayOffModule),
				canActivate: [AuthGuard, RoleGuard],
				data: { menu: Globals.MENU_DAY_OFF }
			},{
				path: 'notification',
				loadChildren: () => import('../../pages/setting/notification/notification.module').then(m => m.NotificationModule),
				canActivate: [AuthGuard, RoleGuard],
				data: { menu: Globals.MENU_NOTIFICATION }
			},{
				path: 'create-menu',
				loadChildren: () => import('../../pages/setting/create-menu/createMenu.module').then(m => m.CreateMenuModule),
				canActivate: [AuthGuard, RoleGuard],
				data: { menu: Globals.MENU_CREATE_SPECIAL_KEY }
			}
		]
	}
];
