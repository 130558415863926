import {Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService} from '@ngx-translate/core';
import Swal from 'sweetalert2'
import { Constants } from '../../constants/constant';
import * as XLSX from 'xlsx';
import { DatePipe } from '@angular/common';

@Injectable()
export class HelperService {
    constructor(
        private router: Router,
        private translateService: TranslateService,
        public datepipe: DatePipe
    ) { }

    handleErrorResponse(error) {
        if (error.statusText === 'Unauthorized') {
            return this.router.navigate(['/login']);
        }
        this.getTranslateAlertSweetResultNotTimer(
            'error',
            this.translateService.instant('alert.error'),
            error.error.msg
        );
    }

    handleErrorResponseBadRequest(error) {
        if (error.statusText === 'Unauthorized') {
            return this.router.navigate(['/login']);
        }
        this.getTranslateAlertSweetResultNotTimer(
            'error',
            this.translateService.instant('alert.error'),
            error.error
        );
    }

    declareCheckBoxSelectionModule(allModules) {
        let module = {};
        let moduleArray = [];
        for (let i in allModules) {
            module = {
                id: allModules[i].id,
                itemName: allModules[i].name
            }
            moduleArray.push(module);
        }

        return moduleArray;
    }

    getCurrentDate() {
        let today = new Date();
        return today.getFullYear() + '/' + today.getMonth() + '/' + today.getDate() + ' 00:00';
    }

    /*
    *  PreferKey Array :key to keep
    *  ObjectArr Array Object :object to sort out the key
    */
    newObjectArrByPreferKey(preferKey, objectArr) {
        for (let i in objectArr) {
            Object.keys(objectArr[i]).forEach(function (key) {
                if (preferKey.indexOf(key) > -1) {
                } else {
                    delete objectArr[i][key];
                }
            });
        }

        return objectArr;
    }

    translateArr(arr) {

        var data = [];
        for (var i = 0; i < arr.length; i++) {
            var text = {};
            if (arr[i].class != undefined) {
                text = this.translateService.instant(arr[i].title);
            } else {
                text = this.translateService.instant(arr[i]);
            }
            i == 0 ? data = [text] : data.push(text);
        }

        return data;
    }

    /**
     * Get alert meesage on delete school by language
     *
     * @param type sweet alert type success|error
     * @param locale current locale en|km|zh
     */
    getTranslateAlertResult(type: string, title: string) {
        // Success alert
        if (type == 'success') {
            return Swal.fire({
                icon: 'success',
                title: title,
                showConfirmButton: false,
                timer: 1500
            }).then(
                function () { },
                function (dismiss) {
                    if (dismiss === 'timer') {
                    }
                }
            )
        } else {
            return Swal.fire({
                icon: 'error',
                text: title,
                width: 370,
                showConfirmButton: false,
                timer: 15000
            }).then(
                function () { },
                function (dismiss) {
                    if (dismiss === 'timer') { }
                }
            )
        }
    }

    /**
     * @param onConfirm
     * @param onCancel
     */
    dialogsSwalConfirmStatus(onConfirm, onCancel) {
        Swal.fire({
            title: this.translateService.instant('alert.change.status.title'),
            text: '',
            icon: 'warning',
            showCancelButton: true,
            cancelButtonText: this.translateService.instant('alert.btn.cancel'),
            confirmButtonText: this.translateService.instant('alert.btn.ok')
        }).then(onConfirm, onCancel);
    }

    /**
     * @param onConfirm
     * @param onCancel
     */
    dialogConfirm(message, onConfirm, onCancel) {
        Swal.fire({
            title: this.translateService.instant(message),
            text: '',
            icon: 'warning',
            showCancelButton: true,
            cancelButtonText: this.translateService.instant('alert.btn.cancel'),
            confirmButtonText: this.translateService.instant('alert.btn.ok')
        }).then(onConfirm, onCancel);
    }

    /**
     *
     * @param type sweet alert type success|error
     * @param title
     * @param text
     */
    getTranslateAlertSweetResult(type: string, title: string, text: string) {


        if (type == 'success') {
            return Swal.fire({
                icon: 'success',
                title: title,
                text: text,
                showConfirmButton: false,
                timer: 1500
            }).then(
                function () { },
                function (dismiss) {
                    if (dismiss === 'timer') { }
                }
            )
        }

        if (type == 'error') {
            return Swal.fire({
                icon: 'error',
                title: title,
                text: text,
                showConfirmButton: false,
                timer: 1500
            }).then(
                function () { },
                function (dismiss) {
                    if (dismiss === 'timer') { }
                }
            )
        }

        if (type == "warning") {
            return Swal.fire({
                icon: 'warning',
                title: title,
                text: text,
                showConfirmButton: false,
                timer: 1500
            }).then(
                function () { },
                function (dismiss) {
                    if (dismiss === 'timer') { }
                }
            )
        }
    }

    /**
     *
     * @param type sweet alert type success|error
     * @param title
     * @param text
     */
    getTranslateAlertSweetResultNotTimer(type: string, title: string, text: string) {
        if (type == 'success') {
            return Swal.fire({
                icon: 'success',
                title: title,
                text: text,
                showConfirmButton: false,
            }).then(
                function () { },
                function (dismiss) {
                    if (dismiss === 'timer') { }
                }
            )
        }

        if (type == 'error') {
            return Swal.fire({
                icon: 'error',
                title: title,
                text: text,
                showConfirmButton: false,
            }).then(
                function () { },
                function (dismiss) {
                    if (dismiss === 'timer') { }
                }
            )
        }

        if (type == "warning") {
            return Swal.fire({
                icon: 'warning',
                title: title,
                text: text,
                showConfirmButton: false,
            }).then(
                function () { },
                function (dismiss) {
                    if (dismiss === 'timer') { }
                }
            )
        }
    }

    prepareTitleTranslation(datas) {
        if (datas == null || datas.length == 0) {
            return;
        }
        datas.forEach((elem) => {
            elem.title = elem.title_en;
            switch (this.translateService.currentLang) {
                case "zh":
                    if (elem.title_cn != "" && elem.title_cn != null) {
                        elem.title = elem.title_cn;
                    }
                    break;
                case "km":
                    if (elem.title_kh != "" && elem.title_kh != null) {
                        elem.title = elem.title_kh;
                    }
                    break;

            }
        });
    }

    getTranslateSelect2Title(result) {
        var data = [];
        for (var i = 0; i < result.length; i++) {
            var text = result[i].title_en;
            switch (this.translateService.currentLang) {
                case "zh":
                    if (result[i].title_cn != "" && result[i].title_cn != null) {
                        text = result[i].title_cn;
                    }
                    break;
                case "km":
                    if (result[i].title_kh != "" && result[i].title_kh != null) {
                        text = result[i].title_kh;
                    }
                    break;
            }
            var obj = {
                id: result[i].id,
                text: text
            };
            i == 0 ? data = [obj] : data.push(obj);
        }

        return data;
    }

    getTranslateSelect2BusinessName(result) {
        var data = [];
        for (var i = 0; i < result.length; i++) {
            var text = result[i].business_name_en;
            switch (this.translateService.currentLang) {
                case "zh":
                    if (result[i].business_name_cn != "" && result[i].business_name_cn != null) {
                        text = result[i].business_name_cn;
                    }
                    break;
                case "km":
                    if (result[i].business_name_kh != "" && result[i].business_name_kh != null) {
                        text = result[i].business_name_kh;
                    }
                    break;
            }
            var obj = {
                id: result[i].id,
                text: text
            };
            i == 0 ? data = [obj] : data.push(obj);
        }

        return data;
    }

    sortTitleSelection2(result) {
        var data = result.sort(function (a, b) {
            var textA = a.text.toUpperCase();
            var textB = b.text.toUpperCase();
            return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
        });

        return data;
    }

    getItemsPerPageList() {
        return [
            {
                value: 10
            },
            {
                value: 20
            },
            {
                value: 50
            },
            {
                value: 100
            },
            {
                value: 200
            },
            {
                value: 500
            }
        ];
    }



    hasAction(menu) {
        let permissions = JSON.parse(localStorage.getItem(Constants.LOCAL_STORAGE_USER_PERMISSIONS));
        let permissionObj = permissions.find(obj => obj.module == menu);
        if (permissionObj != null) {
            return permissionObj.permission > 1;
        }

        return false;
    }

    isURL(str) {
        var pattern = new RegExp('^(https?:\\/\\/)?' + // protocol
            '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.?)+[a-z]{2,}|' + // domain name
            '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
            '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
            '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
            '(\\#[-a-z\\d_]*)?$', 'i'); // fragment locator
        return pattern.test(str);
    }

    formattingDateToString(d: any) {
        if (d == null || d == '') {
            return "";
        }
        var month = d.month;
        var day = d.day;
        if (this.getLengthString(d.month) == 1) {
            month = '0' + d.month
        }
        if (this.getLengthString(d.day) == 1) {
            day = '0' + d.day
        }
        return `${d.year}-${month}-${day}`;
    }

    getLengthString(number) {
        return number.toString().length;
    }

    formattingDateToModel(d: string) {

        let DELIMITER = '-';

        if (d == null || d == '') {
            return "";
        }

        let date = d.split(DELIMITER);

        return { year: parseInt(date[0]), month: parseInt(date[1]), day: parseInt(date[2]) }
    }

    formattingMonthyear(date) {
        return this.datepipe.transform(date, 'MMM-yyyy');
    }

    fixdata(data: any) {
        var o = "", l = 0, w = 10240;
        for (; l < data.byteLength / w; ++l) o += String.fromCharCode.apply(null, new Uint8Array(data.slice(l * w, l * w + w)));
        o += String.fromCharCode.apply(null, new Uint8Array(data.slice(l * w)));
        return o;
    };

    XLSXToCSV(workbook: any) {
        var result = [];
        workbook.SheetNames.forEach(function (sheetName) {
            var csv = XLSX.utils.sheet_to_csv(workbook.Sheets[sheetName]);
            if (csv.length > 0) {
                result.push(csv);
            }
        });
        return result.join("\n");
    };

    formatNumber(number, decimalsLength, decimalSeparator, thousandSeparator) {
        var n = number,
            decimalsLength = isNaN(decimalsLength = Math.abs(decimalsLength)) ? 2 : decimalsLength,
            decimalSeparator = decimalSeparator == undefined ? "," : decimalSeparator,
            thousandSeparator = thousandSeparator == undefined ? "." : thousandSeparator,
            sign = n < 0 ? "-" : "";
        var i = parseInt(n = Math.abs(+n || 0).toFixed(decimalsLength)) + "";
        var j = (j = i.length) > 3 ? j % 3 : 0;

        return sign +
            (j ? i.substr(0, j) + thousandSeparator : "") +
            i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousandSeparator) +
            (decimalsLength ? decimalSeparator + Math.abs(n - parseInt(i)).toFixed(decimalsLength).slice(2) : "");
    }

    isRole() {
        var user = JSON.parse(localStorage.getItem(Constants.LOCAL_STORAGE_USER));
        if (user.role == "asm" || user.role == "saleman" || user.role == "supervisor") {
            return false
        }
        return true
    }

    modulePromission(menuType, specialKeyType) {
        var userPermissions = JSON.parse(localStorage.getItem('user_permissions'));
        var seft = this
        var menu = userPermissions.filter(mm => {
            return mm.Function.find(mmm => {
                return mmm.FunctionName == menuType
            })
        }, seft).map(m => {
            return m.Function.find(mm => {
                return mm.FunctionName == menuType
            })
        }, seft).map(m => {
            return m.SpecialKey.find(mm => {
                return mm.SpecialPermissionKey == specialKeyType
            })
        }).filter(m => {
            return m != undefined
        })

        var t = 0
        if (menu.length > 0 && menu[0].AccessRight == 1) {
            t = 1
        }

        return t
    }

    getLabelAlphabet() {
        return [
            'a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j', 'k', 'l', 'm',
            'n', 'o', 'p', 'q', 'r', 's', 't', 'u', 'v', 'w', 'x', 'y', 'z',
            'aa', 'ab', 'ac', 'ad', 'ae', 'af', 'ag', 'ah', 'ai', 'aj', 'ak', 'al', 'am', 'an', 'ao', 'ap', 'aq',
            'ar', 'as', 'at', 'au', 'av', 'aw', 'ax', 'ay', 'az',
            'ba', 'bb', 'bc', 'bd', 'be', 'bf', 'bg', 'bh', 'bi', 'bj', 'bk', 'bl', 'bm', 'bn', 'bo', 'bp', 'bq',
            'br', 'bs', 'bt', 'bu', 'bv', 'bw', 'bx', 'by', 'bz',
            'ca', 'cb', 'cc', 'cd', 'ce', 'cf', 'cg', 'ch', 'ci', 'cj', 'ck', 'cl', 'cm', 'cn', 'co', 'cp', 'cq',
            'cr', 'cs', 'ct', 'cu', 'cv', 'cw', 'cx', 'cy', 'cz',
            'da', 'db', 'dc', 'dd', 'de', 'df', 'dg', 'dh', 'di', 'dj', 'dk', 'dl', 'dm', 'dn', 'do', 'dp', 'dq',
            'dr', 'ds', 'dt', 'du', 'dv', 'dw', 'dx', 'dy', 'dz',
            'ea', 'eb', 'ec', 'ed', 'ee', 'ef', 'eg', 'eh', 'ei', 'ej', 'ek', 'el', 'em', 'en', 'eo', 'ep', 'eq',
            'er', 'es', 'et', 'eu', 'ev', 'ew', 'ex', 'ey', 'ez',
            'fa', 'fb', 'fc', 'fd', 'fe', 'ff', 'fg', 'fh', 'fi', 'fj', 'fk', 'fl', 'fm', 'fn', 'fo', 'fp', 'fq',
            'fr', 'fs', 'ft', 'fu', 'fv', 'fw', 'fx', 'fy', 'fz',
            'ga', 'gb', 'gc', 'gd', 'ge', 'gf', 'gg', 'gh', 'gi', 'gj', 'gk', 'gl', 'gm', 'gn', 'go', 'gp', 'gq',
            'gr', 'gs', 'gt', 'gu', 'gv', 'gw', 'gx', 'gy', 'gz',
            'ha', 'hb', 'hc', 'hd', 'he', 'hf', 'hg', 'hh', 'hi', 'hj', 'hk', 'hl', 'hm', 'hn', 'ho', 'hp', 'hq',
            'hr', 'hs', 'ht', 'hu', 'hv', 'hw', 'hx', 'hy', 'hz',
            'ia', 'ib', 'ic', 'id', 'ie', 'if', 'ig', 'ii', 'ii', 'ij', 'ik', 'il', 'im', 'in', 'io', 'ip', 'iq',
            'ir', 'is', 'it', 'iu', 'iv', 'iw', 'ix', 'iy', 'iz',
            'ja', 'jb', 'jc', 'jd', 'je', 'jf', 'jg', 'jj', 'ji', 'jj', 'jk', 'jl', 'jm', 'jn', 'jo', 'jp', 'jq',
            'jr', 'js', 'jt', 'ju', 'jv', 'jw', 'jx', 'jy', 'jz',
            'ka', 'kb', 'kc', 'kd', 'ke', 'kf', 'kg', 'kk', 'ki', 'kj', 'kk', 'kl', 'km', 'kn', 'ko', 'kp', 'kq',
            'kr', 'ks', 'kt', 'ku', 'kv', 'kw', 'kx', 'ky', 'kz',
            'la', 'lb', 'lc', 'ld', 'le', 'lf', 'lg', 'll', 'li', 'lj', 'lk', 'll', 'lm', 'ln', 'lo', 'lp', 'lq',
            'lr', 'ls', 'lt', 'lu', 'lv', 'lw', 'lx', 'ly', 'lz',
            'ma', 'mb', 'mc', 'md', 'me', 'mf', 'mg', 'mh', 'mi', 'mj', 'mk', 'ml', 'mm', 'mn', 'mo', 'mp', 'mq',
            'mr', 'ms', 'mt', 'mu', 'mv', 'mw', 'mx', 'my', 'mz',
            'na', 'nb', 'nc', 'nd', 'ne', 'nf', 'ng', 'nh', 'ni', 'nj', 'nk', 'nl', 'nm', 'nn', 'no', 'np', 'nq',
            'nr', 'ns', 'nt', 'nu', 'nv', 'nw', 'nx', 'ny', 'nz',
            'oa', 'ob', 'oc', 'od', 'oe', 'of', 'og', 'oh', 'oi', 'oj', 'ok', 'ol', 'om', 'on', 'oo', 'op', 'oq',
            'or', 'os', 'ot', 'ou', 'ov', 'ow', 'ox', 'oy', 'oz',
            'pa', 'pb', 'pc', 'pd', 'pe', 'pf', 'pg', 'ph', 'pi', 'pj', 'pk', 'pl', 'pm', 'pn', 'po', 'pp', 'pq',
            'pr', 'ps', 'pt', 'pu', 'pv', 'pw', 'px', 'py', 'pz',
            'qa', 'qb', 'qc', 'qd', 'qe', 'qf', 'qg', 'qh', 'qi', 'qj', 'qk', 'ql', 'qm', 'qn', 'qo', 'qp', 'qq',
            'qr', 'qs', 'qt', 'qu', 'qv', 'qw', 'qx', 'qy', 'qz',
            'ra', 'rb', 'rc', 'rd', 're', 'rf', 'rg', 'rh', 'ri', 'rj', 'rk', 'rl', 'rm', 'rn', 'ro', 'rp', 'rq',
            'rr', 'rs', 'rt', 'ru', 'rv', 'rw', 'rx', 'ry', 'rz'
        ]
    }

    getNumberPageExport() {
        return 500
    }

    paginate(array, page_size, page_number) {
        return array.slice((page_number - 1) * page_size, page_number * page_size);
    }

    openSideBar(component: any) {
        let element = component.modal.nativeElement
        document.body.appendChild(element);
        let elem1 = element.getElementsByClassName('main-offcavas')[0]
        let elem2 = element.getElementsByClassName('overlay-offcavas')[0]
        elem1.style.width = '30%'
        elem1.style.display = 'block'
        elem2.style.display = 'block'
        document.body.style.overflow = 'hidden'
    }

    removeComponentOffCanvasBody(){
        document.getElementById('offcanvasSildbar').remove();
        document.body.style.overflow = 'auto'
    }
    
    removeComponentFromBody(elem: any) {
        elem.parentNode.removeChild(elem);
    }
    
    uniqueArray (a){
        return [...new Set(a.map(o => JSON.stringify(o)))].map(mm => JSON.parse(`${mm}`));
    } 

    deepCopy = (obj: any) => {
        if(!obj) return null;
        return JSON.parse(JSON.stringify(obj));
    }
}
