import { RouteInfo } from './sidebar.metadata';
import { Globals } from '../services/globals';

export const ROUTES: RouteInfo[] = [

    {
        path: '/dashboard',
        title: 'menu.dashboard',
        title2: Globals.MENU_DASHBOARD,
        icon: 'ft-home', 
        class: '', 
        badge: '', 
        badgeClass: '', 
        isExternalLink: false,
        submenu: []
    },
    {
        path: '', 
        title: 'menu.master', 
        title2: '',
        icon: 'ft-layers', 
        class: 'has-sub',
        badge: '', 
        badgeClass: '',
        isExternalLink: false,
        submenu: [
            { 
                path: '/master/product',
                title: 'menu.product', 
                title2: Globals.MENU_PRODUCT,
                icon: '',
                class: '', 
                badge: '', 
                badgeClass: '', 

                isExternalLink: false,
                submenu: []
            },{ 
                path: '/master/salesman',
                title: 'menu.salesman', 
                title2: Globals.MENU_SALE_MAN,
                icon: '',
                class: '', 
                badge: '', 
                badgeClass: '', 

                isExternalLink: false,
                submenu: []
            },{
                path: '/master/customer',
                title: 'menu.customer', 
                title2: Globals.MENU_CUSTOMER,
                icon: '',
                class: '', 
                badge: '', 
                badgeClass: '', 

                isExternalLink: false,
                submenu: []
            },{
                path: '/master/zone',
                title: 'menu.zone', 
                title2: Globals.MENU_ZONE,
                icon: '',
                class: '', 
                badge: '', 
                badgeClass: '',

                isExternalLink: false,
                submenu: []
            },{
                path: '/master/pricing-lavel',
                title: 'menu.pricing.lavel', 
                title2: Globals.MENU_PRICINGLAVEL,
                icon: '',
                class: '', 
                badge: '', 
                badgeClass: '',
                isExternalLink: false,
                submenu: []
            },{
                path: '/master/warehouse',
                title: 'menu.warehouse', 
                title2: Globals.MENU_WAREHOUSE,
                icon: '',
                class: '', 
                badge: '', 
                badgeClass: '',
                isExternalLink: false,
                submenu: []
            }
        ]
    },
    {
        path: '',
        title: 'menu.routing.plan',
        title2: "",
        icon: 'ft-trending-up', 
        class: 'has-sub',
        badge: '', 
        badgeClass: '',
        isExternalLink: false,
        submenu: [
            { 
                path: '/routing-plan/routing-plan',
                title: 'menu.routing.plan',
                title2: Globals.MENU_Routine_Plan,
                icon: '',
                class: '', 
                badge: '', 
                badgeClass: '', 
                isExternalLink: false,
                submenu: []
            }
        ]
    },
    {
        path: '', 
        title: 'menu.sale.and.promotion', 
        title2: '',
        icon: 'ft-volume-2', 
        class: 'has-sub',
        badge: '', 
        badgeClass: '',
        isExternalLink: false,
        submenu: [
            {
                path: '/promotion',
                title: 'menu.promotion',
                title2: Globals.MENU_PROMOTION,
                icon: '', 
                class: '', 
                badge: '', 
                badgeClass: '', 

                isExternalLink: false, 
                submenu: []
            },
            {
                path: '/promotion-sku',
                title: 'menu.promotion.sku',
                title2: Globals.MENU_PROMOTION_SKU,
                icon: '', 
                class: '', 
                badge: '', 
                badgeClass: '', 

                isExternalLink: false, 
                submenu: []
            },
            {
                path: '/sale-order',
                title: 'menu.sale.order',
                title2: Globals.MENU_SALE_ORDER,
                icon: '',
                class: '', 
                badge: '', 
                badgeClass: '', 

                isExternalLink: false, 
                submenu: []
            },
            {
                path: '/invoice-order',
                title: 'menu.invoice',
                title2: Globals.MENU_INVOICE,
                icon: '',
                class: '', 
                badge: '',
                badgeClass: '', 
                isExternalLink: false, 
                submenu: []
            },{
                path: '/customer-target',
                title: 'menu.customer.target',
                title2: Globals.MENU_CUSTOMER_TARGET,
                icon: '',
                class: '', 
                badge: '',
                badgeClass: '', 
                isExternalLink: false, 
                submenu: []
            },{
                path: '/discount',
                title: 'menu.discount',
                title2: Globals.MENU_DISCOUNT,
                icon: '',
                class: '', 
                badge: '',
                badgeClass: '', 
                isExternalLink: false, 
                submenu: []
            }
        ]
    },
    {
        path: '', 
        title: 'menu.stock.management', 
        title2: '',
        icon: 'ft-folder', 
        class: 'has-sub',
        badge: '', 
        badgeClass: '',
        isExternalLink: false,
        submenu: [
            {
                path: '/stock-management/receive-stock',
                title: 'menu.receive.stock',
                title2: Globals.MENU_RECEIVE_STOCK,
                icon: '', 
                class: '', 
                badge: '', 
                badgeClass: '', 
                isExternalLink: false, 
                submenu: []
            }
        ]
    },
    {
        path: '', 
        title: 'menu.kpi', 
        title2: '',
        icon: 'ft-package', 
        class: 'has-sub',
        badge: '', 
        badgeClass: '',
        isExternalLink: false,
        submenu: [
            { 
                path: '/kpi/metric-type',
                title: 'menu.metric.type', 
                title2: Globals.MENU_METRIC_TYPE,
                icon: '',
                class: '', 
                badge: '', 
                badgeClass: '', 

                isExternalLink: false,
                submenu: []
            },
            { 
                path: '/kpi/kpi-goal',
                title: 'menu.kpi.goal', 
                title2: Globals.MENU_KPI_GOAL,
                icon: '',
                class: '', 
                badge: '', 
                badgeClass: '',

                isExternalLink: false,
                submenu: []
            }
        ]
    },
    {
        path: '', 
        title: 'menu.marketing', 
        title2: "",
        icon: 'ft-activity', 
        class: 'has-sub',
        badge: '', 
        badgeClass: '',
        isExternalLink: false,
        submenu: [
            {
                path: '/marketing/product-distribution',
                title: 'menu.product.distribution',
                title2: Globals.MENU_PRODUCT_DISTRIBUTE,
                icon: '', 
                class: '', 
                badge: '', 
                badgeClass: '', 
                isExternalLink: false, 
                submenu: []
            },
            {
                path: '/marketing/customer-sku',
                title: 'menu.customer.sku',
                title2: Globals.MENU_CUSTOMER_SKU,
                icon: '', 
                class: '', 
                badge: '', 
                badgeClass: '', 
                isExternalLink: false, 
                submenu: []
            },
            {
                path: '/marketing/competitor',
                title: 'menu.competitor',
                title2: Globals.MENU_COMPETITOR,
                icon: '', 
                class: '', 
                badge: '', 
                badgeClass: '', 
                isExternalLink: false, 
                submenu: []
            },{
                path: '/marketing/customer-kpi',
                title: 'menu.customer.kpi',
                title2: Globals.MENU_CUSTOMER_KPI,
                icon: '', 
                class: '', 
                badge: '', 
                badgeClass: '', 
                isExternalLink: false,
                submenu: []
            },{
                path: '/marketing/posm',
                title: 'menu.posm',
                title2: Globals.MENU_POSM,
                icon: '', 
                class: '', 
                badge: '', 
                badgeClass: '', 
                isExternalLink: false,
                submenu: []
            },{
                path: '/marketing/presentation',
                title: 'menu.presentation',
                title2: Globals.MENU_PRESENTATION,
                icon: '', 
                class: '', 
                badge: '', 
                badgeClass: '', 
                isExternalLink: false,
                submenu: []
            }
        ]
    },
    {
        path: '',
        title: 'menu.financial',
        title2: "",
        icon: 'ft-layers', 
        class: 'has-sub',
        badge: '', 
        badgeClass: '',
        isExternalLink: false,
        submenu: [
            { 
                path: '/financial/collector',
                title: 'menu.collector',
                title2: Globals.MENU_COLLECTOR,
                icon: '',
                class: '', 
                badge: '', 
                badgeClass: '', 
                isExternalLink: false,
                submenu: []
            },{ 
                path: '/financial/collection',
                title: 'menu.collection',
                title2: Globals.MENU_COLLECTION,
                icon: '',
                class: '', 
                badge: '', 
                badgeClass: '', 
                isExternalLink: false,
                submenu: []
            }
        ]
    },
    {
        path: '', 
        title: 'menu.report', 
        title2: '',
        icon: 'ft-pie-chart', 
        class: 'has-sub',
        badge: '', 
        badgeClass: '',
        isExternalLink: false,
        submenu: [
            { 
                path: '/report/promotion/summarize',
                title: 'menu.promotion',
                title2: Globals.MENU_REPORT_PROMOTION,
                icon: '',
                class: '', 
                badge: '', 
                badgeClass: '', 
                isExternalLink: false,
                submenu: []
            },
            { 
                path: '/report/distribution/principal',
                title: 'menu.distribution', 
                title2: Globals.MENU_DISTRIBUTE_BY_PRINCIPAL,
                icon: '',
                class: '', 
                badge: '', 
                badgeClass: '',
                isExternalLink: false,
                submenu: []
            },{ 
                path: '/report/sale/date',
                title: 'menu.sale',
                title2: Globals.MENU_REPORT_SALE,
                icon: '',
                class: '',
                badge: '',
                badgeClass: '',
                isExternalLink: false,
                submenu: []
            },{ 
                path: '/report/routing-plan/month',
                title: 'menu.routing.plan',
                title2: Globals.MENU_REPORT_ROUTING_PLAN,
                icon: '',
                class: '',
                badge: '',
                badgeClass: '',
                isExternalLink: false,
                submenu: []
            },{ 
                path: '/report/kpi-report',
                title: 'menu.kpi.report',
                title2: Globals.MENU_KPI_REPORT,
                icon: '',
                class: '',
                badge: '',
                badgeClass: '',
                isExternalLink: false,
                submenu: []
            },{
                path: '/report/customer',
                title: 'menu.customer.report',
                title2: Globals.MENU_CUSTOMER_REPORT,
                icon: '',
                class: '',
                badge: '',
                badgeClass: '',
                isExternalLink: false,
                submenu: []
            },{
                path: '/report/count-stock/by-saleman',
                title: 'menu.customer.count.stock',
                title2: Globals.MENU_CUSTOMER_COUNT_STOCK,
                icon: '',
                class: '',
                badge: '',
                badgeClass: '',
                isExternalLink: false,
                submenu: []
            },{
                path: '/report/customer-kpi/by-customer',
                title: 'menu.customer.kpi',
                title2: Globals.MENU_CUSTOMER_KPI_REPORT,
                icon: '',
                class: '',
                badge: '',
                badgeClass: '',
                isExternalLink: false,
                submenu: []
            },{
                path: '/report/posm/by-customer',
                title: 'menu.posm',
                title2: Globals.MENU_POSM_REPORT,
                icon: '',
                class: '',
                badge: '',
                badgeClass: '',
                isExternalLink: false,
                submenu: []
            },{
                path: '/report/competitor/by-compititor',
                title: 'menu.competitor',
                title2: Globals.MENU_COMPETITOR_REPORT,
                icon: '',
                class: '',
                badge: '',
                badgeClass: '',
                isExternalLink: false,
                submenu: []
            },{
                path: '/report/presentation/by-summery',
                title: 'menu.presentation',
                title2: Globals.MENU_PRESENTATION_REPORT,
                icon: '',
                class: '',
                badge: '',
                badgeClass: '',
                isExternalLink: false,
                submenu: []
            },{ 
                path: '/report/discount/summarize',
                title: 'menu.discount',
                title2: Globals.MENU_DISCOUNT_REPORT,
                icon: '',
                class: '', 
                badge: '', 
                badgeClass: '', 
                isExternalLink: false,
                submenu: []
            },{ 
                path: '/report/customer-target/by-summery',
                title: 'menu.customer.target',
                title2: Globals.MENU_CUSTOMER_TARGET_REPORT,
                icon: '',
                class: '', 
                badge: '', 
                badgeClass: '', 
                isExternalLink: false,
                submenu: []
            }
        ]
    },
    {
        path: '', 
        title: 'menu.settings', 
        title2: '',
        icon: 'ft-settings', 
        class: 'has-sub',
        badge: '', 
        badgeClass: '',
        isExternalLink: false,
        submenu: [
            { 
                path: '/setting/user',
                title: 'menu.user', 
                title2: Globals.MENU_USER,
                icon: '',
                class: '', 
                badge: '', 
                badgeClass: '',
                isExternalLink: false,
                submenu: []
            },{ 
                path: '/setting/role',
                title: 'menu.role', 
                title2: Globals.MENU_ROLE,
                icon: '',
                class: '', 
                badge: '', 
                badgeClass: '', 
                isExternalLink: false,
                submenu: []
            },
            { 
                path: '/setting/holiday',
                title: 'menu.holiday', 
                title2: Globals.MENU_HOLIDAY,
                icon: '',
                class: '', 
                badge: '', 
                badgeClass: '',
                isExternalLink: false,
                submenu: []
            },
            { 
                path: '/setting/dayoff',
                title: 'menu.day.off', 
                title2: Globals.MENU_DAY_OFF,
                icon: '',
                class: '', 
                badge: '', 
                badgeClass: '',
                isExternalLink: false,
                submenu: []
            },{ 
                path: '/setting/notification',
                title: 'menu.notification',
                title2: Globals.MENU_NOTIFICATION,
                icon: '',
                class: '', 
                badge: '', 
                badgeClass: '',
                isExternalLink: false,
                submenu: []
            },{ 
                path: '/setting/create-menu',
                title: 'menu.create.special.key',
                title2: Globals.MENU_CREATE_SPECIAL_KEY,
                icon: '',
                class: '', 
                badge: '', 
                badgeClass: '',
                isExternalLink: false,
                submenu: []
            }
        ]
    }
];
