import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-drop-down',
  templateUrl: './drop-down.component.html',
  styleUrls: ['./drop-down.component.scss']
})
export class DropDownComponent implements OnInit {

    @Input() buttomText
    @Input() color
    @Input() width
    @Input() classPadding

    constructor() { }

    ngOnInit() {
        
    }
}
