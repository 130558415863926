export class Constants {
    public static LOCAL_STORAGE_USER = "user";
    public static LOCAL_STORAGE_USER_LANGUAGE = "language";
    public static LOCAL_STORAGE_USER_IMAGE = "image_url";
    public static LOCAL_STORAGE_USER_PERMISSIONS = "user_permissions";
    public static LOCAL_STORAGE_USER_TOKEN = "app_token";
    public static LOCAL_STORAGE_USER_REFRESH_TOKEN = "refresh_token";
    public static LOCAL_STORAGE_USER_TOKEN_EXPIRED_AT = "expires_at";
    public static LOCAL_NUM_PAGE = "num_page";
    public static LOCAL_STORAGE_SEARCH = "filter_search";
    public static LOCAL_STORAGE_ZONE = "zones";
    public static LOCAL_STORAGE_DISTRICT = "districts";
    public static LOCAL_STORAGE_COMMUNE = "communes";
    
}

export class MODULE{
    public static ROUTING_PLAN = "routingPlan";
    public static PROMOTION = "promotion";
    public static CUSTOMER_TARGET = "customer_target";
    public static KPI_GOAL = "kpi_goal";
    public static DISCOUNT = "discount";
    public static PRESENTATION = "presentation";
    public static POSM = "POSM";
    public static CUSTOMER_KPI = "customerKpi";
}

export class EXPORT_ACTION_TYPE {
    public static UPLOAD_FILE = 'uploadFile';
    public static REMOVE_FILE = 'removeFile';
}

export class SystemType {
    public static BACKEND = "BackEnd";
    public static MOBILE = "Mobile";
}

export class DateType {
    public static PUBLIC_HOLIDay = 'PublicHoliday';
    public static DAYOFF = 'DayOff';
    public static HALF = "half";
    public static FULL = "full";
    public static NON_VISIT = "NonVisit";
    public static LEAVE = "Leave";
}

export class OEStatus {
    public static OE_SUBMIT = 0;
    public static OE_POSTED = 1;
    public static CONFIRMED_FOR_SHIPPING = 2;
    public static SHIPPED_OUT = 3;
    public static DELIVERING = 4;
    public static DELIVERY_PROBLEM = 5;
    public static DELIVERY_RETURN_NOTE = 6;
    public static CUSTOMER_RECEIVED = 7;
    public static GENERATED_INVOICE = 8; 
}